import App from 'App';
import auth_routes from 'Auth/routes';
import user_route from 'Users/routes';
import customer_route from 'Customers/routes';
import company_route from 'Companies/routes';
import material_route from 'Material/routes';
import order_route from 'Orders/routes';
import statistic_route from 'Statistic/routes';
import customer_debit_route from 'CustomersDebit/routes';
import order_search from 'SearchOrders/routes';
import order_print_list from 'OrderPrint/routes';
import report_revenue from 'Statistic/routes.report';
import customer_income from 'CustomersInCome/routes';
const routes = [].concat(auth_routes, [
  //Array routes import from other folder
  // Example auth_routes
  {
    path: '/',
    component: App,
    private: true,
    routes: [].concat(
      user_route,
      material_route,
      order_search,
      order_route,
      order_print_list,
      customer_debit_route,
      company_route,
      customer_route,
      statistic_route,
      report_revenue,
      customer_income
    )
  }
]);

export default routes;
