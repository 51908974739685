import createReducers from 'create-reducers';
import { actions } from './constants';
const initialState = {
  isBusy: false,
  users: [],
  selectedOrder: [],
  sales: [],
  unfollow: [],
  pending_sales: [],
  canceled: [],
  departments: [],
  delivery_hcm: [],
  delivery_not_hcm: []
};

const statisticReducer = createReducers(initialState, actions);

export default statisticReducer;
