import { handleActions } from 'redux-actions';

const initialState = {
  incomeCustomerList: [],
  incomeListByCustomer: [],
  totalIncomeCustomer: 0,
  totalIncomeListByCustomer: 0,
  isLoadingIncomeCustomerList: false,
  isLoadingIncomeList: false,
  errorMessage: ''
};

const customersListIncomeReducer = handleActions(
  {
    FETCH_CUSTOMERS_LIST_INCOME: (state) => ({
      ...state,
      isLoadingIncomeCustomerList: true
    }),
    FETCH_CUSTOMERS_LIST_INCOME_SUCCESS: (state, actions) => ({
      ...state,
      incomeCustomerList: actions.payload.items,
      totalIncomeCustomer: actions.payload.total,
      isLoadingIncomeCustomerList: false
    }),
    FETCH_CUSTOMERS_LIST_INCOME_FAILED: (state, actions) => ({
      ...state,
      errorMessage: actions.payload.message,
      isLoadingIncomeCustomerList: false
    }),
    FETCH_TOTAL_INCOME_CUSTOMER_REQUEST: (state) => ({
      ...state,
      isLoadingIncomeList: true
    }),
    FETCH_TOTAL_INCOME_CUSTOMER_SUCCESS: (state, actions) => ({
      ...state,
      isLoadingIncomeList: false,
      incomeListByCustomer: actions.payload.items,
      totalIncomeListByCustomer: actions.payload.total
    }),
    FETCH_TOTAL_INCOME_CUSTOMER_FAILED: (state, actions) => ({
      ...state,
      isLoadingIncomeList: false,
      errorMessage: actions.payload.message
    })
  },
  initialState
);

export default customersListIncomeReducer;
