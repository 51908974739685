import React from 'react';

const PrintTypes = React.lazy(() => import('./containers/PrintTypes'));
const Categories = React.lazy(() => import('./containers/Categories'));
const PaperTypes = React.lazy(() => import('./containers/PaperTypes'));
const Outsources = React.lazy(() => import('./containers/Outsources'));
const routes = [].concat([
  {
    path: '/print-types',
    component: PrintTypes,
    title: 'Kiểu in',
    group: 'Nguyên liệu',
    exact: true,
    private: true,
    roles: ['Admin', 'Manager']
  },
  {
    path: '/categories',
    component: Categories,
    title: 'Danh mục hàng hóa',
    group: 'Nguyên liệu',
    exact: true,
    private: true,
    roles: ['Admin','Manager']
  },
  {
    path: '/paper-types',
    component: PaperTypes,
    title: 'Loại giấy',
    group: 'Nguyên liệu',
    exact: true,
    private: true,
    roles: ['Admin','Manager']
  },
  {
    path: '/outsources',
    component: Outsources,
    title: 'Gia công',
    group: 'Nguyên liệu',
    exact: true,
    private: true,
    roles: ['Admin','Manager']
  }
]);

export default routes;
