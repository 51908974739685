export const converListCat = (dataList) => {
  const listParent = [];
  Object.keys(dataList).forEach((val) => {
    const listSub = dataList[val];
    listParent.push({
      name: val,
      id: listSub[0].parent_id
    });
  });
  return listParent;
};

export const listRole = {
  Admin: 'Admin',
  Manager: 'Quản lý',
  Saler: 'Sale',
  Accountant: 'Kế toán',
  Designer: 'Thiết kế',
  Printer: 'In ấn',
  Store: 'Kho',
  Deliver: 'Giao hàng'
};

export const listDataByRole = (data = []) => {
  const keyRole = Object.keys(listRole);
  const listGroupRole = {};
  keyRole.map(value => {
    const dataRole = data.filter(e => e?.roles[0].name === value);
    if (dataRole.length > 0) {
      listGroupRole[value] = dataRole;
    }
  });
  return listGroupRole;
};
