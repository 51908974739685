export const actions = {
  SET_STATE: 'SET_STATE',
  FETCH_PRINT_TYPE: 'FETCH_PRINT_TYPE',
  FETCH_PRINT_TYPE_SUCCESS: 'FETCH_PRINT_TYPE_SUCCESS',
  FETCH_PRINT_TYPE_FAILED: 'FETCH_PRINT_TYPE_FAILED',
  CREATE_PRINT_TYPE: 'CREATE_PRINT_TYPE',
  CREATE_PRINT_TYPE_SUCCESS: 'CREATE_PRINT_TYPE_SUCCESS',
  CREATE_PRINT_TYPE_FAILED: 'CREATE_PRINT_TYPE_FAILED',
  EDIT_PRINT_TYPE: 'EDIT_PRINT_TYPE',
  EDIT_PRINT_TYPE_SUCCESS: 'EDIT_PRINT_TYPE_SUCCESS',
  EDIT_PRINT_TYPE_FAILED: 'EDIT_PRINT_TYPE_FAILED',
  DELETE_PRINT_TYPE: 'DELETE_PRINT_TYPE',
  DELETE_PRINT_TYPE_SUCCESS: 'DELETE_PRINT_TYPE_SUCCESS',
  DELETE_PRINT_TYPE_FAILED: 'DELETE_PRINT_TYPE_FAILED',
  FETCH_CATEGORY: 'FETCH_CATEGORY',
  FETCH_CATEGORY_MOBILE: 'FETCH_CATEGORY_MOBILE',
  FETCH_CATEGORY_SUCCESS: 'FETCH_CATEGORY_SUCCESS',
  FETCH_CATEGORY_MOBILE_SUCCESS: 'FETCH_CATEGORY_MOBILE_SUCCESS',
  FETCH_CATEGORY_FAILED: 'FETCH_CATEGORY_FAILED',
  FETCH_CATEGORY_MOBILE_FAILED: 'FETCH_CATEGORY_MOBILE_FAILED',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILED: 'CREATE_CATEGORY_FAILED',
  EDIT_CATEGORY: 'EDIT_CATEGORY',
  EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',
  EDIT_CATEGORY_FAILED: 'EDIT_CATEGORY_FAILED',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAILED: 'DELETE_CATEGORY_FAILED',
  FETCH_PAPER_TYPE: 'FETCH_PAPER_TYPE',
  FETCH_PAPER_TYPE_SUCCESS: 'FETCH_PAPER_TYPE_SUCCESS',
  FETCH_PAPER_TYPE_FAILED: 'FETCH_PAPER_TYPE_FAILED',
  CREATE_PAPER_TYPE: 'CREATE_PAPER_TYPE',
  CREATE_PAPER_TYPE_SUCCESS: 'CREATE_PAPER_TYPE_SUCCESS',
  CREATE_PAPER_TYPE_FAILED: 'CREATE_PAPER_TYPE_FAILED',
  EDIT_PAPER_TYPE: 'EDIT_PAPER_TYPE',
  EDIT_PAPER_TYPE_SUCCESS: 'EDIT_PAPER_TYPE_SUCCESS',
  EDIT_PAPER_TYPE_FAILED: 'EDIT_PAPER_TYPE_FAILED',
  DELETE_PAPER_TYPE: 'DELETE_PAPER_TYPE',
  DELETE_PAPER_TYPE_SUCCESS: 'DELETE_PAPER_TYPE_SUCCESS',
  DELETE_PAPER_TYPE_FAILED: 'DELETE_PAPER_TYPE_FAILED',
  FETCH_OUTSOURCES: 'FETCH_OUTSOURCES',
  FETCH_OUTSOURCES_SUCCESS: 'FETCH_OUTSOURCES_SUCCESS',
  FETCH_OUTSOURCES_FAILED: 'FETCH_OUTSOURCES_FAILED',
  CREATE_OUTSOURCE: 'CREATE_OUTSOURCE',
  CREATE_OUTSOURCE_SUCCESS: 'CREATE_OUTSOURCE_SUCCESS',
  CREATE_OUTSOURCS_FAILED: 'CREATE_OUTSOURCE_FAILED',
  EDIT_OUTSOURCE: 'EDIT_OUTSOURCE',
  EDIT_OUTSOURCE_SUCCESS: 'EDIT_OUTSOURCE_SUCCESS',
  EDIT_OUTSOURCE_FAILED: 'EDIT_OUTSOURCE_FAILED',
  DELETE_OUTSOURCE: 'DELETE_OUTSOURCE',
  DELETE_OUTSOURCE_SUCCESS: 'DELETE_OUTSOURCE_SUCCESS',
  DELETE_OUTSOURCE_FAILED: 'DELETE_OUTSOURCE_FAILED'
};

export const END_POINT = {
  fetch_print_type: {
    method: 'GET',
    url: 'print-type'
  },
  add_print_type: {
    method: 'POST',
    url: 'print-type'
  },
  edit_print_type: {
    method: 'PUT',
    url: (id) => `print-type/${id}`
  },
  delete_print_type: {
    method: 'DELETE',
    url: (id) => `print-type/${id}`
  },
  fetch_category: {
    method: 'GET',
    url: 'all-category'
  },
  fetch_category_mobile: {
    method: 'GET',
    url: 'categories'
  },
  add_category: {
    method: 'POST',
    url: 'categories'
  },
  edit_category: {
    method: 'PUT',
    url: (id) => `categories/${id}`
  },
  delete_category: {
    method: 'DELETE',
    url: (id) => `categories/${id}`
  },
  fetch_paper_type: {
    method: 'GET',
    url: 'paper-type'
  },
  add_paper_type: {
    method: 'POST',
    url: 'paper-types'
  },
  edit_paper_type: {
    method: 'PUT',
    url: (id) => `paper-types/${id}`
  },
  delete_paper_type: {
    method: 'DELETE',
    url: (id) => `paper-types/${id}`
  },
  fetch_outsource: {
    method: 'GET',
    url: 'outsources'
  },
  add_outsource: {
    method: 'POST',
    url: 'outsources'
  },
  edit_outsource: {
    method: 'PUT',
    url: (id) => `outsources/${id}`
  },
  delete_outsource: {
    method: 'DELETE',
    url: (id) => `outsources/${id}`
  }
};
