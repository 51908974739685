import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, switchMap } from 'rxjs/operators';
import { makeAjaxRequest } from 'utils/ajax';
import { converListCat } from 'utils/utility';
import { actions, END_POINT } from './constants';
import materialActions from './actions';

const fetchPrintTypeEpic = action$ =>
  action$.pipe(
    ofType(actions.FETCH_PRINT_TYPE),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.fetch_print_type.method,
        END_POINT.fetch_print_type.url
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(materialActions.fetchPrintTypeFailed());
          }
          return of(materialActions.fetchPrintTypeSuccess({ printTypes: data.response.data, isBusy: false }));
        })
      )
    )
  );
const createPrintTypeEpic = action$ =>
  action$.pipe(
    ofType(actions.CREATE_PRINT_TYPE),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.add_print_type.method,
        END_POINT.add_print_type.url,
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(materialActions.createPrintTypeFailed({ isBusy: false, errorMessage: data.response.errors }));
          }
          return of(materialActions.createPrintTypeSuccess({ isBusy: false, message: 'Thêm dữ liệu thành công.' }), materialActions.fetchPrintType());
        })
      )
    )
  );

const editPrintTypeEpic = action$ =>
  action$.pipe(
    ofType(actions.EDIT_PRINT_TYPE),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.edit_print_type.method,
        END_POINT.edit_print_type.url(action.payload.id),
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(materialActions.editPrintTypeFailed({ isBusy: false, errorMessage: DataTransfer.response.errors }));
          }
          return of(materialActions.editPrintTypeSuccess({ isBusy: false, message: 'Thành công.' }), materialActions.fetchPrintType());
        })
      )
    )
  );
const deletePrintTypeEpic = action$ =>
  action$.pipe(
    ofType(actions.DELETE_PRINT_TYPE),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.delete_print_type.method,
        END_POINT.delete_print_type.url(action.payload.id)
      ).pipe(mergeMap(data => {
        if (data.response.errors) {
          return of(materialActions.deletePrintTypeFailed({ isBusy: false, errorMessage: DataTransfer.response.errors }));
        }
        return of(materialActions.deletePrintTypeSuccess({ isBusy: false }), materialActions.fetchPrintType());
      }))
    )
  );

const fetchCategoryEpic = action$ =>
  action$.pipe(
    ofType(actions.FETCH_CATEGORY),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.fetch_category.method,
        END_POINT.fetch_category.url
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(materialActions.fetchCategoryFailed());
          }
          return of(materialActions.fetchCategorySuccess({ categories: data.response.data, isBusy: false }));
        })
      )
    )
  );
const fetchCategoryMobileEpic = action$ =>
  action$.pipe(
    ofType(actions.FETCH_CATEGORY_MOBILE),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.fetch_category_mobile.method,
        END_POINT.fetch_category_mobile.url
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(materialActions.fetchCategoryMobileFailed());
          }
          const categoryParent = converListCat(data.response.data);
          return of(materialActions.fetchCategoryMobileSuccess({ categoriesMobile: data.response.data, categoriesParent: categoryParent, isBusy: false }));
        })
      )
    )
  );
const createCategoryEpic = action$ =>
  action$.pipe(
    ofType(actions.CREATE_CATEGORY),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.add_category.method,
        END_POINT.add_category.url,
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(materialActions.createCategoryFailed({ isBusy: false, errorMessage: data.response.errors }));
          }
          return of(materialActions.createCategorySuccess({ isBusy: false, message: 'Thêm dữ liệu thành công.' }), materialActions.fetchCategory());
        })
      )
    )
  );

const editCategoryEpic = action$ =>
  action$.pipe(
    ofType(actions.EDIT_CATEGORY),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.edit_category.method,
        END_POINT.edit_category.url(action.payload.id),
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(materialActions.editCategoryFailed({ isBusy: false, errorMessage: data.response.errors }));
          }
          return of(materialActions.editCategorySuccess({ isBusy: false, message: 'Thành công.' }), materialActions.fetchCategory());
        })
      )
    )
  );
const deleteCategoryEpic = action$ =>
  action$.pipe(
    ofType(actions.DELETE_CATEGORY),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.delete_category.method,
        END_POINT.delete_category.url(action.payload.id)
      ).pipe(mergeMap(data => {
        if (data.response.errors) {
          return of(materialActions.deleteCategoryFailed({ isBusy: false, errorMessage: data.response.errors }));
        }
        return of(materialActions.deleteCategorySuccess({ isBusy: false }), materialActions.fetchCategory());
      }))
    )
  );

const fetchPaperTypeEpic = action$ =>
  action$.pipe(
    ofType(actions.FETCH_PAPER_TYPE),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.fetch_paper_type.method,
        END_POINT.fetch_paper_type.url
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(materialActions.fetchPaperTypeFailed());
          }
          return of(materialActions.fetchPaperTypeSuccess({ paperTypes: data.response.data, isBusy: false }));
        })
      )
    )
  );
const createPaperTypeEpic = action$ =>
  action$.pipe(
    ofType(actions.CREATE_PAPER_TYPE),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.add_paper_type.method,
        END_POINT.add_paper_type.url,
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(materialActions.createPaperTypeFailed({ isBusy: false, errorMessage: data.response.errors }));
          }
          return of(materialActions.createPaperTypeSuccess({ isBusy: false, message: 'Thêm dữ liệu thành công.' }), materialActions.fetchPaperType());
        })
      )
    )
  );

const editPaperTypeEpic = action$ =>
  action$.pipe(
    ofType(actions.EDIT_PAPER_TYPE),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.edit_paper_type.method,
        END_POINT.edit_paper_type.url(action.payload.id),
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(materialActions.editPaperTypeFailed({ isBusy: false, errorMessage: data.response.errors }));
          }
          return of(materialActions.editPaperTypeSuccess({ isBusy: false, message: 'Thành công.' }), materialActions.fetchPaperType());
        })
      )
    )
  );
const deletePaperTypeEpic = action$ =>
  action$.pipe(
    ofType(actions.DELETE_PAPER_TYPE),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.delete_paper_type.method,
        END_POINT.delete_paper_type.url(action.payload.id)
      ).pipe(mergeMap(data => {
        if (data.response.errors) {
          return of(materialActions.deletePaperTypeFailed({ isBusy: false, errorMessage: data.response.errors }));
        }
        return of(materialActions.deletePaperTypeSuccess({ isBusy: false }), materialActions.fetchPaperType());
      }))
    )
  );

const fetchOutsourcesEpic = action$ =>
  action$.pipe(
    ofType(actions.FETCH_OUTSOURCES),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.fetch_outsource.method,
        END_POINT.fetch_outsource.url
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(materialActions.fetchOutsourcesFailed());
          }
          return of(materialActions.fetchOutsourcesSuccess({ outsources: data.response.data, isBusy: false }), materialActions.fetchPaperType());
        })
      )
    )
  );
const createOutsourcesEpic = action$ =>
  action$.pipe(
    ofType(actions.CREATE_OUTSOURCE),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.add_outsource.method,
        END_POINT.add_outsource.url,
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(materialActions.createOutsourceFailed({ isBusy: false, errorMessage: data.response.errors }));
          }
          return of(materialActions.createOutsourceSuccess({ isBusy: false, message: 'Thêm dữ liệu thành công.' }), materialActions.fetchPaperType());
        })
      )
    )
  );

const editOutsourcesEpic = action$ =>
  action$.pipe(
    ofType(actions.EDIT_OUTSOURCE),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.edit_outsource.method,
        END_POINT.edit_outsource.url(action.payload.id),
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(materialActions.editOutsourceFailed({ isBusy: false, errorMessage: data.response.errors }));
          }
          return of(materialActions.editOutsourceSuccess({ isBusy: false, message: 'Thành công.' }), materialActions.fetchPaperType());
        })
      )
    )
  );
const deleteOutsourcesEpic = action$ =>
  action$.pipe(
    ofType(actions.DELETE_OUTSOURCE),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.delete_outsource.method,
        END_POINT.delete_outsource.url(action.payload.id)
      ).pipe(mergeMap(data => {
        if (data.response.errors) {
          return of(materialActions.deleteOutsourceFailed({ isBusy: false, errorMessage: data.response.errors }));
        }
        return of(materialActions.deleteOutsourceSuccess({ isBusy: false }), materialActions.fetchPaperType());
      }))
    )
  );

export default [
  fetchPrintTypeEpic,
  createPrintTypeEpic,
  editPrintTypeEpic,
  deletePrintTypeEpic,
  fetchCategoryEpic,
  fetchCategoryMobileEpic,
  createCategoryEpic,
  editCategoryEpic,
  deleteCategoryEpic,
  fetchPaperTypeEpic,
  createPaperTypeEpic,
  editPaperTypeEpic,
  deletePaperTypeEpic,
  fetchOutsourcesEpic,
  createOutsourcesEpic,
  editOutsourcesEpic,
  deleteOutsourcesEpic
];
