import React, { Component, Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import authActions from 'Auth/actions';
import materialActions from 'Material/actions';
import reportActions from 'Statistic/actions.report';
import Loader from 'Components/Loader';
import Navigation from 'Components/Navigation';
import NavBar from 'Components/NavBar';
import { checkRoles } from 'utils';
import { endOfMonth ,format , startOfMonth} from 'date-fns';
import './App.scss';

class App extends Component {
  componentDidMount() {
    const { currentUser } = this.props;

    if (!currentUser || !currentUser.id) {
      this.props.authActions.fetchUserInfo();
    }
    this.props.materialActions.fetchPaperType();
    this.props.materialActions.fetchCategoryMobile();
    if (currentUser && currentUser.id) {
      this.getReportUser(currentUser.id);
    }
  }

  componentDidUpdate(prevProps) {
    const {currentUser} = this.props;
    if (currentUser.id !== prevProps?.currentUser?.id && currentUser.id !== '') {
      this.getReportUser(currentUser.id);
    }
  }

  getReportUser = (idUser) => {
    const currDate = new Date();
    const last_date_in_last_month = format(currDate,'yyyy-MM-dd');
    const first_day_in_year = `${currDate.getFullYear()}-01-01`;
    currDate.setMonth(currDate.getMonth() - 1);
    const date_to = format(endOfMonth(currDate),'yyyy-MM-dd');
    const date_from = format(startOfMonth(currDate),'yyyy-MM-dd');
    this.props.reportActions.fetchReportEmployee({
      employeeLastMonth: {
        isLoad: true
      },
      params: {
        employee_id: idUser,
        date_from,
        date_to,
        isLastMonth: true
      }
    });
    setTimeout(() => {
      this.props.reportActions.fetchReportEmployee({
        employeeInYear: {
          isLoad: true
        },
        params: {
          employee_id: idUser,
          date_from: first_day_in_year,
          date_to: last_date_in_last_month,
          isInYear: true
        }
      });
    }, 500);
  }
  // checkRoles = (roles) => {
  //   const { currentUser } = this.props;
  //   const userRoles = currentUser.roles.map(item => {
  //     return item.name;
  //   });
  //   return isEqual(roles, userRoles);
  // }

  render() {
    const {
      currentUser,
      routes,
      isLoadEmployeeInYear,
      isLoadEmployeeLastMonth,
      dataEmployeeInYear,
      dataEmployeeLastMonth
    } = this.props;
    if (!currentUser || !currentUser.id) {
      return <Loader />;
    }
    const menu = routes.map((route, index) => {
      return route.component && checkRoles(route.roles, currentUser) ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => <route.component {...props} />}
        />
      ) : null;
    });
    return (
      <>
        <Navigation
          routes={routes}
          user={currentUser}
          isLoadEmployeeInYear={isLoadEmployeeInYear}
          isLoadEmployeeLastMonth={isLoadEmployeeLastMonth}
          dataEmployeeInYear={dataEmployeeInYear}
          dataEmployeeLastMonth={dataEmployeeLastMonth}
        />
        <NavBar user={currentUser} />
        <div className='pcoded-main-container'>
          <div className='pcoded-wrapper'>
            <div className='pcoded-content'>
              <div className='pcoded-inner-content'>
                <div className='main-body'>
                  <div className='page-wrapper'>
                    <Suspense fallback={<Loader />}>
                      <Switch>
                        {menu}
                        <Redirect exact from='/' to='orders-processing' />
                      </Switch>
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.AuthReducer.currentUser,
  isLoadEmployeeInYear: state.ReportVenueReducer.employeeInYear.isLoad,
  dataEmployeeInYear: state.ReportVenueReducer.employeeInYear.data,
  isLoadEmployeeLastMonth: state.ReportVenueReducer.employeeLastMonth.isLoad,
  dataEmployeeLastMonth: state.ReportVenueReducer.employeeLastMonth.data
});

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(authActions, dispatch),
  materialActions: bindActionCreators(materialActions, dispatch),
  reportActions: bindActionCreators(reportActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
