import { handleActions } from 'redux-actions';

const initialState = {
  companies: [],
  cities: [],
  isBusy: false
};

const companiesReducer = handleActions({
  SET_STATE: (state, action) => ({
    ...state,
    ...action.payload
  }),
  FETCH_COMPANIES: (state) => ({
    ...state,
    isBusy: true
  }),
  FETCH_COMPANIES_SUCCESS: (state, actions) => ({
    ...state,
    companies: actions.payload,
    isBusy: false
  }),
  FETCH_CITIES: (state) => ({
    ...state,
    isBusy: true
  }),
  FETCH_CITIES_SUCCESS: (state, actions) => ({
    ...state,
    cities: actions.payload,
    isBusy: false
  }),
  CREATE_COMPANY_SUCCESS: (state, actions) => ({
    ...state,
    message: 'Tạo công ty thành công.',
    isBusy: false
  }),
  CREATE_COMPANY_FAILED: (state, actions) => ({
    ...state,
    errorMessage: actions.payload.errorMessage,
    message: null,
    isBusy: false
  }),
  EDIT_COMPANY_SUCCESS: (state, actions) => ({
    ...state,
    message: 'Sửa công ty thành công.',
    isBusy: false
  })
}, initialState);

export default companiesReducer;
