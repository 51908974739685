import React from 'react';
const CustomersDebit = React.lazy(() => import('./view/CustomerDebit'));
const CustomerDebitDetail = React.lazy(() => import('./view/CustomerDebitDetail'));
const routes = [].concat([
  {
    path: '/customers-debit-list',
    component: CustomersDebit,
    title: 'Danh sách công nợ',
    exact: true,
    private: true,
    roles: ['Admin', 'Accountant']
  },
  {
    path: '/:company_id/customers-debit',
    component: CustomerDebitDetail,
    title: 'Chi tiết công nợ khách hàng',
    exact: true,
    private: true,
    hideMenu: true,
    roles: ['Admin', 'Accountant']
  }
]);

export default routes;
