import React from 'react';

const PrintWaitingList = React.lazy(() => import('./view/PrintWaitingList'));
const PrintingList = React.lazy(() => import('./view/PrintingList'));
const routes = [].concat([
  {
    path: '/danh-sach-cho-in',
    component: PrintWaitingList,
    title: 'Danh sách chờ in',
    exact: true,
    private: true,
    roles: ['Admin', 'Printer']
  },
  {
    path: '/danh-sach-dang-in',
    component: PrintingList,
    title: 'Danh sách đang in',
    exact: true,
    private: true,
    roles: ['Admin', 'Printer']
  }
]);

export default routes;
