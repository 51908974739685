import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { makeAjaxRequest } from 'utils/ajax';
import { actions, END_POINT } from './constants';
import sessionActions from './actions';
import { reportActions } from 'Statistic/constants';

const loginEpic = (action$, dispatch) => {
  return action$.pipe(
    ofType(actions.LOGIN),
    mergeMap(action =>
      makeAjaxRequest(
        END_POINT.sign_in.method,
        END_POINT.sign_in.url,
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(sessionActions.loginReject(data.response.errors));
          } else {
            const user = data.response.data.user;
            localStorage.setItem('access_token', data.response.data.token);
            return of(sessionActions.loginSuccess(user));
          }
        }),
        catchError(error => { })
      )
    )
  );
};

const fetchUserEpic = (action$) => {
  return action$.pipe(
    ofType(actions.FETCH_USER_INFO),
    mergeMap(action =>
      makeAjaxRequest(
        END_POINT.fetch_user.method,
        END_POINT.fetch_user.url
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            localStorage.removeItem('access_token');
            return of(sessionActions.fetchUserInfoFailed(data.response.errors));
          } else {
            const user = data.response.data.user;
            return of(sessionActions.fetchUserInfoSuccess(user));
          }
        }),
        catchError(error => { })
      )
    )
  );
};

const logoutEpic = (action$) => {
  return action$.pipe(
    ofType(actions.LOGOUT),
    mergeMap(() =>
      makeAjaxRequest(
        END_POINT.logout.method,
        END_POINT.logout.url
      ).pipe(
        mergeMap(data => {
          localStorage.removeItem('access_token');
          return of(sessionActions.logoutSuccess());
        }),
        catchError(error => { console.log(error); })
      )
    )
  );
};

const profileEpic = (action$) => {
  return action$.pipe(
    ofType(actions.UPDATE_PROFILE),
    mergeMap((action) =>
      makeAjaxRequest(
        END_POINT.profile.method,
        END_POINT.profile.url,
        action.payload
      ).pipe(
        mergeMap(data => {
          const user = data.response.data.user;
          return of(sessionActions.updateProfileSuccess(), sessionActions.fetchUserInfoSuccess(user));
        }),
        catchError(error => { console.log(error); })
      )
    )
  );
};

export default [loginEpic, fetchUserEpic, logoutEpic, profileEpic];
