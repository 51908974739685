import { combineReducers } from 'redux';
import AuthReducer from './Auth/reducers';
import UsersReducer from './Users/reducers';
import CustomersReducer from './Customers/reducers';
import CompaniesReducer from './Companies/reducers';
import MaterialReducer from './Material/reducers';
import OrdersReducer from './Orders/reducers';
import StatisticReducer from './Statistic/reducers';
import CustomersDebitListReducer from './CustomersDebit/reducers';
import SearchReducer from './SearchOrders/reducers';
import CustomersListIncomeReducer from './CustomersInCome/reducers';
import OrderPrintReducer from './OrderPrint/reducer';
import ReportVenueReducer from './Statistic/reducer.report';

const rootReducer = combineReducers({
  AuthReducer,
  UsersReducer,
  CustomersReducer,
  MaterialReducer,
  OrdersReducer,
  CompaniesReducer,
  StatisticReducer,
  CustomersDebitListReducer,
  SearchReducer,
  OrderPrintReducer,
  ReportVenueReducer,
  CustomersListIncomeReducer
});

export default rootReducer;
