import React from 'react';
const CustomerIncomeList = React.lazy(() =>
  import('./view/CustomerIncomeList')
);
const CustomeInComeListDetail = React.lazy(() =>
  import('./view/CustomeInComeListDetail')
);
const routes = [].concat([
  {
    path: '/customers-income-list',
    component: CustomerIncomeList,
    title: 'Doanh số khách hàng',
    exact: true,
    private: true,
    roles: ['Admin', 'Accountant']
  },
  {
    path: '/customers-income-detail/:company_id',
    component: CustomeInComeListDetail,
    title: 'Chi tiết doanh thu khách hàng',
    exact: true,
    private: false,
    hideMenu: true,
    roles: ['Admin', 'Accountant']
  }
]);

export default routes;
