import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch } from 'react-router-dom';
import PrivateRoute from 'Components/Routes/PrivateRoute';
import PublicRoute from 'Components/Routes/PublicRoute';
import routes from 'routes';

class AppRoutes extends React.Component {
  static propTypes = {
    history: PropTypes.any,
    authenticated: PropTypes.bool,
    location: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      pathname: ''
    };
  }

  render() {
    const authenticated = !!localStorage['access_token'];
    let privateRoutes = [];
    let publicRoutes = [];
    // let match = null;
    // console.log(routes);
    for (let i = 0; i < routes.length; i++) {
      // match = matchPath(this.props.location.pathname, {
      //   path: routes[i].path,
      //   exact: true,
      //   sensitive: routes[i].component.sensitive
      // });
      // if (match) {
      if (routes[i].private) {
        privateRoutes.push(routes[i]);
      } else {
        publicRoutes.push(routes[i]);
      }
      // }
    }
    return (<Switch>
      {publicRoutes.map((publicRoute, i) => {
        return <PublicRoute
          authed={authenticated}
          component={publicRoute.component}
          exact
          // routes={route.routes}
          path={publicRoute.path}
          key={'public'}
        />;
      })
      }
      {privateRoutes.map((privateRoute, i) => {
        return <PrivateRoute
          authed={authenticated}
          component={privateRoute.component}
          routes={privateRoute.routes}
          path={privateRoute.path}
          key={'private' + i}
        />;
      })}
    </Switch>);
  }
}

export default withRouter(AppRoutes);
