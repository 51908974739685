import createReducers from 'create-reducers';
import { actions } from './constants';
const initialState = {
  isBusy: false,
  orders: [],
  order: {},
  totalDebitProcessing: 0,
  totalDebitNeedCheck: 0
};

const ordersReducer = createReducers(initialState, actions);

export default ordersReducer;
