import { ofType } from 'redux-observable';
import { of} from 'rxjs';
import { mergeMap, catchError, switchMap } from 'rxjs/operators';
import { makeAjaxRequest } from 'utils/ajax';
import { actions, END_POINT } from './constants';
import usersActions from './actions';

const fetchUsersEpic = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_USERS),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.fetch_users.method,
        END_POINT.fetch_users.url
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(usersActions.fetchUsersFailed(data.response.errors));
          }
          return of(usersActions.fetchUsersSuccess(data.response.data));
        })
      )
    ));

const createUserEpic = (action$) =>
  action$.pipe(
    ofType(actions.CREATE_USER),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.create_user.method,
        END_POINT.create_user.url,
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(usersActions.createUserFailed(data.response.errors));
          }
          return of(usersActions.createUserSuccess(data.response.data));
        }),
        catchError(error => {console.log(error);})
      )
    )
  );

const editUserEpic = (action$) =>
  action$.pipe(
    ofType(actions.EDIT_USER),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.edit_user.method,
        END_POINT.edit_user.url(action.payload.id),
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(usersActions.editUserFailed(data.response.errors));
          }
          return of(usersActions.editUserSuccess(data.response.data));
        }),
        catchError(error => {console.log(error);})
      )
    )
  );

const deleteUserEpic = action$ =>
  action$.pipe(
    ofType(actions.DELETE_USER),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.delete_user.method,
        END_POINT.delete_user.url(action.payload.id)
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(usersActions.deleteUserFailed(data.response.errors));
          }
          return of(usersActions.deleteUserSuccess(data.response));
        }),
        catchError(error => {console.log(error);})
      )
    )
  );
export default [fetchUsersEpic, createUserEpic, editUserEpic, deleteUserEpic];
