import createReducers from 'create-reducers';
import { actions } from './constants';
const initialState = {
  isLoadingSearch: false,
  orderListSearch: [],
  totalOrder: 0
};

const searchReducer = createReducers(initialState, actions);

export default searchReducer;
