import React from 'react';

const Statistic = React.lazy(() => import('./containers/Statistic'));
const StatisticIncome = React.lazy(() => import('./containers/StatisticIncome'));

const routes = [].concat([
  {
    path: '/statistic',
    component: Statistic,
    title: 'Thống kê đơn hàng sale',
    exact: true,
    private: true,
    roles: ['Admin']
  }
  // {
  //   path: '/statistic-income',
  //   component: StatisticIncome,
  //   title: 'Thống kê phòng ban',
  //   exact: true,
  //   private: true,
  //   roles: ['Admin']
  // }
]);

export default routes;
