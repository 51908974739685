import React from 'react';
const Customers = React.lazy(() => import('./containers/Customers'));
const routes = [].concat([
  {
    path: '/customers',
    component: Customers,
    title: 'Khách hàng',
    exact: true,
    private: true,
    roles: ['Admin']
  },
  {
    path: '/customers',
    component: Customers,
    title: 'Khách hàng',
    exact: true,
    private: true,
    roles: ['Accountant']
  }
]);

export default routes;
