export const actions = {
  SET_ORDER_STATE: 'SET_ORDER_STATE',
  FETCH_ORDERS: 'FETCH_ORDERS',
  FETCH_ORDERS_SUCCESS: 'FETCH_ORDERS_SUCCESS',
  FETCH_ORDERS_FAILED: 'FETCH_ORDERS_FAILED',
  FETCH_ORDERS3: 'FETCH_ORDERS3',
  FETCH_ORDERS3_SUCCESS: 'FETCH_ORDERS3_SUCCESS',
  FETCH_ORDERS3_FAILED: 'FETCH_ORDERS3_FAILED',
  FETCH_TOTAL_DEBIT_PROCESSING: 'FETCH_TOTAL_DEBIT_PROCESSING',
  FETCH_TOTAL_DEBIT_PROCESSING_FAILED: 'FETCH_TOTAL_DEBIT_PROCESSING_FAILED',
  FETCH_TOTAL_DEBIT_PROCESSING_SUCCESS: 'FETCH_TOTAL_DEBIT_PROCESSING_SUCCESS',
  FETCH_TOTAL_DEBIT_NEED_CHECK: 'FETCH_TOTAL_DEBIT_NEED_CHECK',
  FETCH_TOTAL_DEBIT_NEED_CHECK_SUCCESS: 'FETCH_TOTAL_DEBIT_NEED_CHECK_SUCCESS',
  FETCH_TOTAL_DEBIT_NEED_CHECK_FAILED: 'FETCH_TOTAL_DEBIT_NEED_CHECK_FAILED',
  ORDER_DETAIL: 'ORDER_DETAIL',
  ORDER_DETAIL_FAILED: 'ORDER_DETAIL_FAILED',
  ORDER_DETAIL_SUCCESS: 'ORDER_DETAIL_SUCCESS',
  EDIT_ORDER: 'EDIT_ORDER',
  EDIT_ORDER_SUCCESS: 'EDIT_ORDER_SUCCESS',
  EDIT_ORDER_FAILED: 'EDIT_ORDER_FAILED',
  GET_ORDER_IDS: 'GET_ORDER_IDS',
  GET_ORDER_IDS_SUCCESS: 'GET_ORDER_IDS_SUCCESS',
  GET_ORDER_IDS_FAILED: 'GET_ORDER_IDS_FAILED',
  SEND_MAIL_DEBIT: 'SEND_MAIL_DEBIT',
  SEND_MAIL_DEBIT_SUCCESS: 'SEND_MAIL_DEBIT_SUCCESS',
  SEND_MAIL_DEBIT_FAILED: 'SEND_MAIL_DEBIT_FAILED',
  SEND_MAIL_DEBIT_LIST: 'SEND_MAIL_DEBIT_LIST',
  SEND_MAIL_DEBIT_LIST_SUCCESS: 'SEND_MAIL_DEBIT_LIST_SUCCESS',
  SEND_MAIL_DEBIT_LIST_FAILED: 'SEND_MAIL_DEBIT_LIST_FAILED'
};

export const END_POINT = {
  fetch_orders: {
    method: 'GET',
    url: (params) => `orders2?${params}`
  },
  fetch_orders3: {
    method: 'GET',
    url: (params) => `orders3?${params}`
  },
  order_detail: {
    method: 'GET',
    url: (order_id) => `orders/${order_id}`
  },
  edit_order: {
    method: 'PUT',
    url: (order_id) => `orders/${order_id}`
  },
  get_order_ids: {
    method: 'POST',
    url: 'order-detail'
  },
  send_mails: {
    method: 'POST',
    url: 'send-receipt'
  },
  total_debit_processing: {
    url: 'total-inprogress',
    method: 'GET'
  },
  total_debit_need_check: {
    url: 'total-receivables',
    method: 'GET'
  }
};

export const ORDER_STATUS = {
  '-1': 'Đã hủy',
  0: 'Nháp',
  1: 'Sale',
  2: 'Đang thiết kế',
  3: 'Chờ phản hồi thiết kế',
  4: 'Đang thiết kế theo phản hồi',
  5: 'Đã thiết kế',
  6: 'Chờ in',
  7: 'Đã in',
  8: 'Lưu kho',
  9: 'Giao hàng',
  10: 'Hoàn thành'
};

export const DEPARTMENT = {
  '-1': 'Đã hủy',
  0: 'Kinh doanh',
  1: 'Kinh doanh',
  2: 'Thiết kế',
  3: 'Thiết kế',
  4: 'Thiết kế',
  5: 'Thiết kế',
  6: 'In ấn',
  7: 'In Ấn',
  8: 'Kho',
  9: 'Vận chuyển',
  10: 'Vận chuyển'
};
export const PAGE = {
  ORDER_NEED_CHECK: 'orderNeedCheck',
  ORDER_LIST_DEBIT: 'orderListDebit',
  ORDER_CONFIRM: 'orderConfirm',
  ORDER_INCOME: 'orderIncome'
};
