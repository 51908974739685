export const actions = {
  FETCH_SEARCH_ORDER: 'FETCH_SEARCH_ORDER',
  FETCH_CLEAR_ORDER: 'FETCH_CLEAR_ORDER',
  FETCH_SEARCH_ORDER_SUCCESS: 'FETCH_SEARCH_ORDER_SUCCESS',
  FETCH_SEARCH_ORDER_FAILED: 'FETCH_SEARCH_ORDER_FAILED'
};

export const END_POINT = {
  fetch_search: {
    method: 'GET',
    url: (params) => `search?${params}`
  }
};
