function loadDevTools(callback) {
  if (
    (process.env.NODE_ENV === 'development')
  ) {
    // app code so we don't worry about bundle size.
    import('./dev-tools')
      .then(devTools => devTools.install())
      .finally(callback);
  } else {
    // if we don't need the DevTools, call the callback immediately.
    callback();
  }
}
export default loadDevTools;
