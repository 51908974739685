export const actions = {
  FETCH_CUSTOMERS_LIST_INCOME_REQUEST: 'FETCH_CUSTOMERS_LIST_INCOME_REQUEST',
  FETCH_CUSTOMERS_LIST_INCOME_SUCCESS: 'FETCH_CUSTOMERS_LIST_INCOME_SUCCESS',
  FETCH_CUSTOMERS_LIST_INCOME_FAILED: 'FETCH_CUSTOMERS_LIST_INCOME_FAILED',
  FETCH_TOTAL_INCOME_CUSTOMER_REQUEST: 'FETCH_TOTAL_INCOME_CUSTOMER_REQUEST',
  FETCH_TOTAL_INCOME_CUSTOMER_SUCCESS: 'FETCH_TOTAL_INCOME_CUSTOMER_SUCCESS',
  FETCH_TOTAL_INCOME_CUSTOMER_FAILED: 'FETCH_TOTAL_INCOME_CUSTOMER_FAILED'
};

export const END_POINT = {
  fetch_customers_list_income: {
    url: (params) => `reports/customers?${params}`,
    method: 'GET'
  },
  fetch_order_list_income_customer: {
    url: (params, id) => `reports/customers/${id}?${params}`,
    method: 'GET'
  }
};
