export const actions = {
  SET_STATE: 'SET_STATE',
  FETCH_COMPANIES: 'FETCH_COMPANIES',
  FETCH_COMPANIES_FAILED: 'FETCH_COMPANIES_FAILED',
  FETCH_COMPANIES_SUCCESS: 'FETCH_COMPANIES_SUCCESS',
  FETCH_CITIES: 'FETCH_CITIES',
  FETCH_CITIES_FAILED: 'FETCH_CITIES_FAILED',
  FETCH_CITIES_SUCCESS: 'FETCH_CITIES_SUCCESS',
  CREATE_COMPANY: 'CREATE_COMPANY',
  CREATE_COMPANY_FAILED: 'CREATE_COMPANY_FAILED',
  CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
  EDIT_COMPANY: 'EDIT_COMPANY',
  EDIT_COMPANY_SUCCESS: 'EDIT_COMPANY_SUCCESS',
  EDIT_COMPANY_FAILED: 'EDIT_COMPANY_FAILED',
  DELETE_COMPANY: 'DELETE_COMPANY',
  DELETE_COMPANY_SUCCESS: 'DELETE_COMPANY_SUCCESS',
  DELETE_COMPANY_FAILED: 'DELETE_COMPANY_FAILED'
};

export const END_POINT = {
  fetch_companies: {
    url: 'companies',
    method: 'GET'
  },
  fetch_cities: {
    url: 'address',
    method: 'GET'
  },
  create_company: {
    url: 'companies',
    method: 'POST'
  },
  edit_company: {
    url: (uid) => `companies/${uid}`,
    method: 'PUT'
  },
  delete_company: {
    url: (uid) => `companies/${uid}`,
    method: 'DELETE'
  }
};
