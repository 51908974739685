import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import authActions from '../actions';
import reportActions from 'Statistic/actions.report';

class Logout extends React.Component {

  componentDidMount() {
    this.props.authActions.logout();
  }

  render() {
    const {currentUser} = this.props;
    if (!currentUser || !currentUser.id) {
      return <Redirect to='/signin' />;
    }
    return null;
  }
}
Logout.propTypes = {
  authActions: PropTypes.object,
  currentUser: PropTypes.object
};
const mapStateToProps = (state) => ({
  currentUser: state.AuthReducer.currentUser
});
const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(authActions, dispatch),
  reportActions: bindActionCreators(reportActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
