import { combineEpics } from 'redux-observable';
import authEpics from './Auth/epics';
import usersEpics from './Users/epics';
import customersEpics from './Customers/epics';
import companiesEpics from './Companies/epics';
import materialEpics from './Material/epics';
import ordersEpics from './Orders/epics';
import statisticEpics from './Statistic/epics';
import CustomerDebitEpics from './CustomersDebit/epics';
import SearchEpics from './SearchOrders/epics';
import OrderPrintEpics from './OrderPrint/epics';
import ReportEpics from './Statistic/epics.report';
import CustomerIncomeEpics from './CustomersInCome/epics';
const rootEpics = combineEpics(
  ...ReportEpics,
  ...authEpics,
  ...usersEpics,
  ...materialEpics,
  ...ordersEpics,
  ...customersEpics,
  ...statisticEpics,
  ...companiesEpics,
  ...CustomerDebitEpics,
  ...SearchEpics,
  ...OrderPrintEpics,
  ...CustomerIncomeEpics
);
export default rootEpics;
