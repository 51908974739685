import { ofType } from 'redux-observable';
import { of} from 'rxjs';
import { mergeMap, catchError, switchMap } from 'rxjs/operators';
import { makeAjaxRequest } from 'utils/ajax';
import { actions, END_POINT } from './constants';
import statisticActions from './actions';

const fetchStatisticEpic = action$ =>
  action$.pipe(
    ofType(actions.FETCH_STATISTIC),
    switchMap(action => {
      let query = Object.keys(action.payload.params).map((key) => {
        return key + '=' + action.payload.params[key];
      }).join('&');
      return makeAjaxRequest(
        END_POINT.fetch_statistic.method,
        END_POINT.fetch_statistic.url(query)
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(statisticActions.fetchStatisticFailed(data.response.errors));
          }
          return of(statisticActions.fetchStatisticSuccess({...data.response.data}));
        })
      );
    })
  );
const fetchStatisticIncomeEpic = action$ =>
  action$.pipe(
    ofType(actions.FETCH_STATISTIC_INCOME),
    switchMap(action => {
      let query = Object.keys(action.payload.params).map((key) => {
        return key + '=' + action.payload.params[key];
      }).join('&');
      return makeAjaxRequest(
        END_POINT.fetch_income.method,
        END_POINT.fetch_income.url(query)
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(statisticActions.fetchStatisticIncomeFailed({...data.response.errors, isBusy: false}));
          }
          return of(statisticActions.fetchStatisticIncomeSuccess({users: data.response.data, isBusy: false}));
        })
      );
    })
  );
const fetchOrderDetailEpic = action$ =>
  action$.pipe(
    ofType(actions.FETCH_ORDER_DETAIL),
    switchMap(action => {
      return makeAjaxRequest(
        END_POINT.fetch_order.method,
        END_POINT.fetch_order.url(action.payload.order_id)
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(statisticActions.fetchOrderDetailFailed(data.response.errors));
          }
          return of(statisticActions.fetchOrderDetailSuccess({selectedOrder: data.response.data}));
        })
      );
    })
  );
export default [fetchStatisticEpic, fetchOrderDetailEpic, fetchStatisticIncomeEpic];
