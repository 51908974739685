import React from 'react';
import { Card } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
const SPACING = 22;

const shortLine = (pShortY) => (
  <>
    <rect x='0' y={pShortY} rx='3' ry='3' width='22%' height='10' />
    <rect x='23%' y={pShortY} rx='3' ry='3' width='22%' height='10' />
    <rect x='46%' y={pShortY} rx='3' ry='3' width='22%' height='10' />
    <rect x='69%' y={pShortY} rx='3' ry='3' width='22%' height='10' />
  </>
);
const longLine = (pLongY) => (
  <>
    <rect x='4%' y={pLongY} rx='3' ry='3' width='30%' height='10' />
    <rect x='35%' y={pLongY} rx='3' ry='3' width='30%' height='10' />
    <rect x='66%' y={pLongY} rx='3' ry='3' width='30%' height='10' />
  </>
);
const shortOneLine = () => (
  <rect x='0' y={8} rx='3' ry='3' width='100%' height='10' />
);
const line1 = 8;
const line2 = line1 + SPACING;
const line3 = line2 + SPACING;
const line4 = line3 + SPACING;
const LoadingReport = () => {
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={160}
      viewBox='0 0 100% 160'
      backgroundColor='#90dfda'
      foregroundColor='#ecebeb'
    >
      {shortLine(line1)}
      {longLine(line2)}
      {shortLine(line3)}
      {longLine(line4)}
    </ContentLoader>
  );
};

export const LoadingTwoLine = () => {
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={160}
      viewBox='0 0 100% 160'
      backgroundColor='#90dfda'
      foregroundColor='#ecebeb'
    >
      {shortOneLine()}
    </ContentLoader>
  );
};
export default LoadingReport;
