
import * as Constant from 'utils/appConstants';

const { MODE } = Constant;
export default class DynamicUrl {
  static instance = null;
  mainUrl = null;

  /**
   * @returns {DynamicUrl}
   */
  static getInstance() {
    if (DynamicUrl.instance === null) {
      DynamicUrl.instance = new DynamicUrl();
    }
    return this.instance;
  }

  /**
   * Set Url
   * @param mode
   */
  setUrl = (mode = null) => {
    if (mode && mode.key === 'develop') {
      this.mainUrl = process.env.REACT_APP_API_URL_DEV;
    }
    else if (mode && mode.key === 'production') {
      this.mainUrl = process.env.REACT_APP_API_URL;
    }
    localStorage.setItem('base_url', this.mainUrl);
  }

  /**
   * Get Url
   * @returns {mainUrl}
   */
  getUrl = () => {
    if (process.env.NODE_ENV === 'production') {
      return process.env.REACT_APP_API_URL;
    }
    if (this.mainUrl !== null) {
      return this.mainUrl;
    }
    this.mainUrl = localStorage.getItem('base_url') || process.env.REACT_APP_API_URL_DEV;
    return this.mainUrl;
  }
}
