import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Card, Button, Alert, Tab, Form } from 'react-bootstrap';
import authActions from '../actions';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {}
    };
  }

  handleFormControlChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    const { profile } = this.state;
    profile[name] = value;
    this.setState({profile});
  }

  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    this.setState({validated: true});
    if (form.checkValidity()) {
      const { selectedCompany } = this.state;
      this.setState({validated: false});
      if (selectedCompany.id) {
        this.props.authActions.updateProfile(selectedCompany);
      }
    }
  }
  render() {
    const {currentUser, errorMessage, message} = this.props;
    const { validated } = this.state;
    return <Row>
      <Col>
        <Card>
          <Card.Header>
            <Card.Title as='h5'>Cập nhật thông tin cá nhân</Card.Title>
          </Card.Header>
          <Card.Body>
            <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
              {(errorMessage) &&
                <Alert variant='danger'>
                  {errorMessage}
                </Alert>
              }
              {(message) &&
                <Alert variant='success'>
                  {message}
                </Alert>
              }
              <Form.Row>
                <Form.Group as={Col} md='12'>
                  <Form.Label>Họ</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    name='last_name'
                    placeholder='Họ'
                    defaultValue={currentUser.last_name}
                    onChange={this.handleFormControlChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Vui lòng nhập họ
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md='12'>
                  <Form.Label>Tên</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    name='first_name'
                    placeholder='Tên'
                    defaultValue={currentUser.first_name}
                    onChange={this.handleFormControlChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Vui lòng nhập tên
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md='12'>
                  <Form.Label>Mật khẩu cũ</Form.Label>
                  <Form.Control
                    required
                    type='password'
                    name='last_password'
                    placeholder='Mật khẩu cũ'
                    onChange={this.handleFormControlChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Vui lòng nhập họ
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md='12'>
                  <Form.Label>Mật khẩu mới</Form.Label>
                  <Form.Control
                    required
                    type='password'
                    name='new_password'
                    placeholder='Mật khẩu mới'
                    onChange={this.handleFormControlChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Vui lòng nhập họ
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>;
  }
}
Profile.propTypes = {
  authActions: PropTypes.object,
  currentUser: PropTypes.object,
  message: PropTypes.object,
  errorMessage: PropTypes.object
};
const mapStateToProps = (state) => ({
  currentUser: state.AuthReducer.currentUser
});
const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(authActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
