import React from 'react';

const ReportSummary = React.lazy(() => import('./view/ReportSummary'));
const ReportEmployee = React.lazy(() => import('./view/ReportEmployee'));

const reportRoutes = [].concat([
  {
    path: '/report-summary',
    component: ReportSummary,
    title: 'Thống kê tổng quát',
    exact: true,
    private: true,
    roles: ['Admin']
  },
  {
    path: '/report-employee',
    component: ReportEmployee,
    title: 'Thống kê cá nhân',
    exact: true,
    private: true,
    roles: [
      'Admin',
      'Accountant',
      'Designer',
      'Manager',
      'Printer',
      'Saler',
      'Store'
    ]
  }
]);

export default reportRoutes;
