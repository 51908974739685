import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { makeAjaxRequest } from 'utils/ajax';
import { actions, END_POINT } from './constants';
import customerListIncomeActions from './actions';

const fetchCustomersListIncomeRequest = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_CUSTOMERS_LIST_INCOME_REQUEST),
    switchMap((action) => {
      let query = Object.keys(action.payload.params)
        .map((key) => {
          return key + '=' + action.payload.params[key];
        })
        .join('&');
      return makeAjaxRequest(
        END_POINT.fetch_customers_list_income.method,
        END_POINT.fetch_customers_list_income.url(query)
      ).pipe(
        mergeMap((data) => {
          if (data.response.errors) {
            return of(
              customerListIncomeActions.fetchCustomersListIncomeFailed(
                data.response.errors
              )
            );
          }
          return of(
            customerListIncomeActions.fetchCustomersListIncomeSuccess(
              data.response.data
            )
          );
        }),
        catchError((error) => {
          console.log(error);
        })
      );
    })
  );
const fetchTotalIncomeCustomerEpic = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_TOTAL_INCOME_CUSTOMER_REQUEST),
    switchMap((action) => {
      console.log(action);
      const company_id = action.payload.company_id;
      let query = Object.keys(action.payload.params)
        .map((key) => {
          return key + '=' + action.payload.params[key];
        })
        .join('&');
      return makeAjaxRequest(
        END_POINT.fetch_order_list_income_customer.method,
        END_POINT.fetch_order_list_income_customer.url(query, company_id)
      ).pipe(
        mergeMap((data) => {
          if (data.response.errors) {
            console.log(data.response);
            return of(
              customerListIncomeActions.fetchTotalIncomeCustomerFailed(
                data.response.errors
              )
            );
          }
          return of(
            customerListIncomeActions.fetchTotalIncomeCustomerSuccess(
              data.response.data
            )
          );
        }),
        catchError((error) => {
          console.log(error);
        })
      );
    })
  );
export default [fetchCustomersListIncomeRequest, fetchTotalIncomeCustomerEpic];
