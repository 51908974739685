import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import windowSize from 'react-window-size';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink } from 'react-router-dom';

import NavLogo from './NavLogo';
// import NavContent from './NavContent';
import OutsideClick from './OutsideClick';
import { checkRoles, numberWithCommas } from 'utils';
import { LoadingTwoLine } from 'Statistic/view/ReportSummary/LoadingReport';
// import navigation from '../../../../menu-items';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseMenu: false
    };
  }

  componentDidMount() {
    this.resize();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  onToggleNavigation = () => {
    const { collapseMenu } = this.state;
    this.setState({ collapseMenu: !collapseMenu });
  }
  resize = () => {
    const contentWidth = document.getElementById('root').clientWidth;

    if (this.props.layout === 'horizontal' && contentWidth < 992) {
      this.props.onChangeLayout('vertical');
    }
  };

  render() {
    let navClass = [
      'pcoded-navbar'
    ];
    const { collapseMenu } = this.state;
    const { user } = this.props;
    if (this.props.preLayout !== null && this.props.preLayout !== '' && this.props.preLayout !== 'layout-6' && this.props.preLayout !== 'layout-8') {
      navClass = [...navClass, this.props.preLayout];
    } else {
      navClass = [
        ...navClass,
        this.props.layoutType,
        this.props.navBackColor,
        this.props.navBrandColor,
        'drp-icon-' + this.props.navDropdownIcon,
        'menu-item-icon-' + this.props.navListIcon,
        this.props.navActiveListColor,
        this.props.navListTitleColor
      ];

      if (this.props.layout === 'horizontal') {
        navClass = [...navClass, 'theme-horizontal'];
      }

      if (this.props.navBackImage) {
        navClass = [...navClass, this.props.navBackImage];
      }

      if (this.props.navIconColor) {
        navClass = [...navClass, 'icon-colored'];
      }

      if (!this.props.navFixedLayout) {
        navClass = [...navClass, 'menupos-static'];
      }

      if (this.props.navListTitleHide) {
        navClass = [...navClass, 'caption-hide'];
      }
    }

    if (this.props.windowWidth < 992 && collapseMenu) {
      navClass = [...navClass, 'mob-open'];
    } else if (collapseMenu) {
      navClass = [...navClass, 'navbar-collapsed'];
    }

    if (this.props.preLayout === 'layout-6') {
      document.body.classList.add('layout-6');
      document.body.style.backgroundImage = this.props.layout6Background;
      document.body.style.backgroundSize = this.props.layout6BackSize;
    }

    if (this.props.preLayout === 'layout-8') {
      document.body.classList.add('layout-8');
    }

    if (this.props.layoutType === 'dark') {
      document.body.classList.add('datta-dark');
    } else {
      document.body.classList.remove('datta-dark');
    }

    if (this.props.rtlLayout) {
      document.body.classList.add('datta-rtl');
    } else {
      document.body.classList.remove('datta-rtl');
    }

    if (this.props.boxLayout) {
      document.body.classList.add('container');
      document.body.classList.add('box-layout');
    } else {
      document.body.classList.remove('container');
      document.body.classList.remove('box-layout');
    }
    const { routes, isLoadEmployeeInYear, isLoadEmployeeLastMonth, dataEmployeeInYear, dataEmployeeLastMonth } = this.props;

    const navItems = routes.map((item, i) => {
      if (item.hideMenu) { return null; }
      if (item.title && checkRoles(item.roles, user)) {
        return <li key={i}><NavLink to={item.path} className='nav-link' exact={true} >{item.title}</NavLink></li>;
      }
      return null;
    });
    let navContent = (
      <div className='navbar-wrapper'>
        <NavLogo collapseMenu={collapseMenu} windowWidth={this.props.windowWidth} onToggleNavigation={this.onToggleNavigation} />
        <div className='navbar-content datta-scroll'>
          <PerfectScrollbar>
            <ul className='nav pcoded-inner-navbar'>
              {navItems}
              <li className='nav-link revenue'>{isLoadEmployeeInYear ? <LoadingTwoLine/> : 'Đơn trong năm: ' + dataEmployeeInYear.total_order}</li>
              <li className='nav-link revenue'>{isLoadEmployeeInYear ? <LoadingTwoLine/> : 'Doanh thu trong năm: ' + numberWithCommas(dataEmployeeInYear.total_income)}</li>
              <li className='nav-link revenue'>{isLoadEmployeeLastMonth ? <LoadingTwoLine/> : 'Đơn tháng trước: ' + dataEmployeeLastMonth.total_order}</li>
              <li className='nav-link revenue'>{isLoadEmployeeLastMonth ? <LoadingTwoLine/> : 'Doanh thu tháng trước: ' + numberWithCommas(dataEmployeeLastMonth.total_income)}</li>
            </ul>
          </PerfectScrollbar>
        </div>
        {/* <NavContent navigation={navigation.items} /> */}
      </div>
    );
    if (this.props.windowWidth < 992) {
      navContent = (
        <OutsideClick>
          <div className='navbar-wrapper'>
            <NavLogo collapseMenu={collapseMenu} windowWidth={this.props.windowWidth} onToggleNavigation={this.onToggleNavigation} />
            {/* <NavContent navigation={navigation.items} /> */}
          </div>
        </OutsideClick>
      );
    }

    return (
      <>
        <nav className={navClass.join(' ')}>
          {navContent}
        </nav>
      </>
    );
  }
}
Navigation.propTypes = {
  user: PropTypes.object,
  windowWidth: PropTypes.number,
  boxLayout: PropTypes.bool,
  rtlLayout: PropTypes.bool,
  layout6Background: PropTypes.bool,
  navListTitleHide: PropTypes.bool,
  navIconColor: PropTypes.bool,
  onChangeLayout: PropTypes.func,
  layout6BackSize: PropTypes.number,
  layout: PropTypes.string,
  navListTitleColor: PropTypes.string,
  navListIcon: PropTypes.string,
  layoutType: PropTypes.string,
  navFixedLayout: PropTypes.string,
  navBackImage: PropTypes.string,
  navActiveListColor: PropTypes.string,
  navBrandColor: PropTypes.string,
  navBackColor: PropTypes.string,
  navDropdownIcon: PropTypes.string,
  preLayout: PropTypes.any,
  routes: PropTypes.array

};
export default withRouter(windowSize(Navigation));
