export const actions = {
  SET_STATE: 'SET_STATE',
  FETCH_CUSTOMERS: 'FETCH_CUSTOMERS',
  FETCH_CUSTOMERS_FAILED: 'FETCH_CUSTOMERS_FAILED',
  FETCH_CUSTOMERS_SUCCESS: 'FETCH_CUSTOMERS_SUCCESS',
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  CREATE_CUSTOMER_FAILED: 'CREATE_CUSTOMER_FAILED',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  EDIT_CUSTOMER: 'EDIT_CUSTOMER',
  EDIT_CUSTOMER_SUCCESS: 'EDIT_CUSTOMER_SUCCESS',
  EDIT_CUSTOMER_FAILED: 'EDIT_CUSTOMER_FAILED',
  DELETE_CUSTOMER: 'DELETE_CUSTOMER',
  DELETE_CUSTOMER_SUCCESS: 'DELETE_CUSTOMER_SUCCESS',
  DELETE_CUSTOMER_FAILED: 'DELETE_CUSTOMER_FAILED'
};

export const END_POINT = {
  fetch_customers: {
    url: (params) => `customers?${params}`,
    method: 'GET'
  },
  create_customer: {
    url: 'customers',
    method: 'POST'
  },
  edit_customer: {
    url: (uid) => `customers/${uid}`,
    method: 'PUT'
  },
  delete_customer: {
    url: (uid) => `customers/${uid}`,
    method: 'DELETE'
  }
};
