export const actions = {
  FETCH_CUSTOMERS_LIST_DEBIT: 'FETCH_CUSTOMERS_LIST_DEBIT',
  FETCH_CUSTOMERS_FAILED: 'FETCH_CUSTOMERS_FAILED',
  FETCH_CUSTOMERS_LIST_DEBIT_SUCCESS: 'FETCH_CUSTOMERS_LIST_DEBIT_SUCCESS',
  FETCH_CUSTOMER_LIST_NAME_REQUEST: 'FETCH_CUSTOMER_LIST_NAME',
  FETCH_CUSTOMER_LIST_NAME_SUCCESS: 'FETCH_CUSTOMER_LIST_NAME_SUCCESS',
  FETCH_CUSTOMER_LIST_NAME_FAILED: 'FETCH_CUSTOMER_LIST_NAME_FAILED',
  FETCH_TOTAL_DEBIT_REQUEST: 'FETCH_TOTAL_DEBIT_REQUEST',
  FETCH_TOTAL_DEBIT_SUCCESS: 'FETCH_TOTAL_DEBIT_SUCCESS',
  FETCH_TOTAL_DEBIT_FAILED: 'FETCH_TOTAL_DEBIT_FAILED'
};

export const END_POINT = {
  fetch_customers_list_debit: {
    url: (params) => `customer-debit?${params}`,
    method: 'GET'
  },
  fetch_total_debit_and_paid: {
    url: 'total-debit',
    method: 'GET'
  }
};
