import { PrinterOrder } from './constants';
export const getChildByCategory = (listCategory, tab) => {
  if (listCategory.length < 1) {
    return '';
  }
  if (listCategory[tab]) {
    const listChild = listCategory[tab];
    if (listChild.length > 0) {
      const listId = Array.from(listChild, (val) => {
        return val.id;
      });
      return listId.toString();
    }
  }
  if (tab === PrinterOrder.FILTER_CATEGORY.OTHERS) {
    const parseArrayList = Object.entries(listCategory);
    let listRestChild = [];
    parseArrayList.filter((parent) => {
      if (
        parent[0] === PrinterOrder.FILTER_CATEGORY.CARD ||
        parent[0] === PrinterOrder.FILTER_CATEGORY.STICKER ||
        parent[0] === PrinterOrder.FILTER_CATEGORY.PAPER_HAND
      ) {
        return '';
      }
      const restParent = Array.from(parent[1], (val) => {
        return val.id;
      });
      listRestChild = listRestChild.concat(restParent);
      return listRestChild;
    });
    return listRestChild.toString();
  }
  return '';
};

export const getListCategoryId = (listCategory, tab) => {
  switch (tab) {
    case PrinterOrder.TAB.CARD_ORDER:
      return getChildByCategory(listCategory, PrinterOrder.FILTER_CATEGORY.CARD);
    case PrinterOrder.TAB.STICKER:
      return getChildByCategory(listCategory, PrinterOrder.FILTER_CATEGORY.STICKER);
    case PrinterOrder.TAB.PAPER:
      return getChildByCategory(listCategory, PrinterOrder.FILTER_CATEGORY.PAPER_HAND);
    default:
      return getChildByCategory(listCategory, PrinterOrder.FILTER_CATEGORY.OTHERS);
  }
};

export const mergeParamsToUrl = (
  status,
  order_by,
  sort_by,
  per_page,
  get_page = 1,
  paramsFilter
) => {
  const statusOrder = `status=${status}`;
  const orderBy = `order_by=${order_by}`;
  const sortBy = `sort_direction=${sort_by}`;
  const perPage = `per_page=${per_page}`;
  const page = `page=${get_page}`;
  const addMoreParams = paramsFilter && paramsFilter.field ? `&${paramsFilter.field}=${paramsFilter.value}` : '';
  return `?${statusOrder}&${orderBy}&${sortBy}&${perPage}&${page}${addMoreParams}`;
};
