import { ofType } from 'redux-observable';
import { of} from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { makeAjaxRequest } from 'utils/ajax';
import { reportActions as actions, END_POINT } from './constants';
import reportActions from './actions.report';
import orderBy from 'lodash/orderBy';
import _ from 'lodash';
import { listDataByRole } from 'utils/utility';

const fetchReportCategoriesEpic = (action$) => action$.pipe(
  ofType(actions.FETCH_REPORT_CATEGORIES),
  switchMap(action => {
    let query = Object.keys(action.payload.params).map((key) => {
      return key + '=' + action.payload.params[key];
    }).join('&');
    return makeAjaxRequest(
      END_POINT.fetch_report_categories.method,
      END_POINT.fetch_report_categories.url(query)
    ).pipe(
      mergeMap(data => {
        if (data.response.errors) {
          return of(reportActions.fetchReportCategoriesFailed({
            categories: {
              isLoad: false,
              ...data.response.errors
            }
          }));
        }
        const sortData = orderBy(data.response.data,['total_cost'],['desc']);
        return of(reportActions.fetchReportCategoriesSuccess({
          categories: {
            isLoad: false,
            data: sortData
          }
        }));
      }),
      catchError((error) => {
        console.log(error);
      })
    );
  })
);

const fetchReportPrintTypesEpic = action$ => action$.pipe(
  ofType(actions.FETCH_REPORT_PRINT_TYPES),
  switchMap(action => {
    let query = Object.keys(action.payload.params).map((key) => {
      return key + '=' + action.payload.params[key];
    }).join('&');
    return makeAjaxRequest(
      END_POINT.fetch_report_print_types.method,
      END_POINT.fetch_report_print_types.url(query)
    ).pipe(
      mergeMap(data => {
        if (data.response.errors) {
          return of(reportActions.fetchReportPrintTypesFailed({
            printTypes: {
              isLoad: false,
              ...data.response.errors
            }
          }));
        }
        const sortData = orderBy(data.response.data,['total_cost'],['desc']);
        return of(reportActions.fetchReportPrintTypesSuccess({
          printTypes: {
            isLoad: false,
            data: sortData
          }
        }));
      }),
      catchError((error) => {
        console.log(error);
      })
    );
  })
);

const fetchReportIncomeEpic = (action$) => action$.pipe(
  ofType(actions.FETCH_REPORT_INCOME),
  switchMap(action => {
    let query = Object.keys(action.payload.params).map((key) => {
      return key + '=' + action.payload.params[key];
    }).join('&');
    return makeAjaxRequest(
      END_POINT.fetch_report_income.method,
      END_POINT.fetch_report_income.url(query)
    ).pipe(
      mergeMap(data => {
        if (data.response.errors) {
          if (action.payload.params.isCurrent) {
            return of(reportActions.fetchReportIncomeFailed({
              incomeCurrent: {
                isLoad: action.params.isCurrent && false,
                ...data.response.errors
              }
            }));
          } else {
            return of(reportActions.fetchReportIncomeFailed({
              incomeRest: {
                isLoad: action.params.isCurrent && false,
                ...data.response.errors
              }
            }));
          }
        }
        if (action.payload.params.isCurrent) {
          return of(reportActions.fetchReportIncomeSuccess({
            incomeCurrent: {
              isLoad: false,
              data: data.response.data[0]
            }
          }));
        } else if (action.payload.params.isRest) {
          return of(reportActions.fetchReportIncomeSuccess({
            incomeRest: {
              isLoad: false,
              data: data.response.data[0]
            }
          }));
        }
      }),
      catchError((error) => {
        console.log(error);
      })
    );
  })
);

const fetchReportEmployeeEpic = action$ => action$.pipe(
  ofType(actions.FETCH_REPORT_EMPLOYEE),
  switchMap(action => {
    if (action.payload.params.isLogout) {
      return new Promise(() => {});
    }
    let query = Object.keys(action.payload.params).map((key) => {
      return key + '=' + action.payload.params[key];
    }).join('&');
    return makeAjaxRequest(
      END_POINT.fetch_report_employee.method,
      END_POINT.fetch_report_employee.url(query)
    ).pipe(
      mergeMap(data => {
        if (data.response.errors) {
          return of(reportActions.fetchReportEmployeeFailed({
            employeeLastMonth: {
              isLoad: false,
              ...data.response.errors
            }
          }));
        }
        if (action.payload.params.isCurrent) {
          return of(reportActions.fetchReportEmployeeSuccess({
            employeeCurrent: {
              data: data.response.data[0],
              isLoad: false
            }
          }));
        } else if (action.payload.params.isLastMonth) {
          return of(reportActions.fetchReportEmployeeSuccess({
            employeeLastMonth: {
              data: data.response.data[0],
              isLoad: false
            }
          }));
        } else if (action.payload.params.isInYear) {
          return of(reportActions.fetchReportEmployeeSuccess({
            employeeInYear: {
              data: data.response.data[0],
              isLoad: false
            }
          }));
        }
      }),
      catchError((error) => {
        console.log(error);
      })
    );
  })
);

const fetchReportEmployeeChartEpic = action$ => action$.pipe(
  ofType(actions.FETCH_REPORT_EMPLOYEE_CHART),
  switchMap(action => {
    let query = Object.keys(action.payload.params).map((key) => {
      return key + '=' + action.payload.params[key];
    }).join('&');
    return makeAjaxRequest(
      END_POINT.fetch_report_employee_chart.method,
      END_POINT.fetch_report_employee_chart.url(query)
    ).pipe(
      mergeMap(data => {
        if (data.response.errors) {
          return of(reportActions.fetchReportEmployeeFailed({
            employeeChartInYear: {
              isLoad: false,
              ...data.response.errors
            }
          }));
        }
        return of(reportActions.fetchReportEmployeeChartSuccess({
          employeeChartInYear: {
            data: data.response.data,
            isLoad: false
          }
        }));
      }),
      catchError((error) => {
        console.log(error);
      })
    );
  })
);
const fetchReportAllEmployeeIncomeEpic = (action$, state) =>
  action$.pipe(
    ofType(actions.FETCH_REPORT_ALL_EMPLOYEE_INCOME),
    switchMap(action => {
      let query = Object.keys(action.payload.params).map((key) => {
        return key + '=' + action.payload.params[key];
      }).join('&');
      return makeAjaxRequest(
        END_POINT.fetch_income.method,
        END_POINT.fetch_income.url(query)
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(reportActions.fetchReportAllEmployeeIncomeFailed({...data.response.errors, allUser: {isLoad: false}}));
          }
          const listUserRevenue = listDataByRole(data.response.data);
          return of(reportActions.fetchReportAllEmployeeIncomeSuccess({
            allUser: {
              data: listUserRevenue,
              isLoad: false
            }
          }));
        })
      );
    })
  );
export default [
  fetchReportCategoriesEpic,
  fetchReportPrintTypesEpic,
  fetchReportIncomeEpic,
  fetchReportEmployeeChartEpic,
  fetchReportEmployeeEpic,
  fetchReportAllEmployeeIncomeEpic
];
