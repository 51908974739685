import React, {Component} from 'react';
import PropTypes from 'prop-types';
import NavLeft from './NavLeft';
import NavRight from './NavRight';

class NavBar extends Component {
  render() {
    let headerClass = ['navbar', 'pcoded-header', 'navbar-expand-lg', this.props.headerBackColor];
    if (this.props.headerFixedLayout) {
      headerClass = [...headerClass, 'headerpos-fixed'];
    }

    let toggleClass = ['mobile-menu'];
    if (this.props.collapseMenu) {
      toggleClass = [...toggleClass, 'on'];
    }

    return (
      <>
        <header className={headerClass.join(' ')}>
          <div className='m-header'>
            <a className={toggleClass.join(' ')} id='mobile-collapse1' href='#!' onClick={this.props.onToggleNavigation}><span/></a>
            <a href='#!' className='b-brand'>
              <div className='b-bg'>
                <i className='feather icon-trending-up'/>
              </div>
              <span className='b-title'>Datta Able</span>
            </a>
          </div>
          <a className='mobile-menu' id='mobile-header' href='#!'><i className='feather icon-more-horizontal'/></a>
          <div className='collapse navbar-collapse'>
            <NavLeft/>
            <NavRight rtlLayout={this.props.rtlLayout} user={this.props.user} />
          </div>
        </header>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    rtlLayout: state.rtlLayout,
    headerBackColor: state.headerBackColor,
    headerFixedLayout: state.headerFixedLayout,
    collapseMenu: state.collapseMenu
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleNavigation: () => dispatch({type: actionTypes.COLLAPSE_MENU})
  };
};
NavBar.propTypes = {
  user: PropTypes.object,
  rtlLayout: PropTypes.string,
  collapseMenu: PropTypes.bool,
  headerBackColor: PropTypes.string,
  onToggleNavigation: PropTypes.func,
  headerFixedLayout: PropTypes.string
};
export default NavBar;
