import { ofType } from 'redux-observable';
import { of} from 'rxjs';
import { mergeMap, catchError, switchMap, flatMap } from 'rxjs/operators';
import orderBy from 'lodash/orderBy';
import { makeAjaxRequest } from 'utils/ajax';
import { actions, END_POINT } from './constants';
import companyActions from './actions';

const fetchCompaniesEpic = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_COMPANIES),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.fetch_companies.method,
        END_POINT.fetch_companies.url
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(companyActions.fetchCompaniesFailed(data.response.errors));
          }
          return of(companyActions.fetchCompaniesSuccess(data.response.data));
        })
      )
    ));

const fetchCitiesEpic = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_CITIES),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.fetch_cities.method,
        END_POINT.fetch_cities.url
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(companyActions.fetchCitiesFailed(data.response.errors));
          }
          const listSort = orderBy(data.response.data,['address_type','id'],['asc','desc']);
          return of(companyActions.fetchCitiesSuccess(listSort));
        })
      )
    ));

const createCompanyEpic = (action$) =>
  action$.pipe(
    ofType(actions.CREATE_COMPANY),
    flatMap(action =>
      makeAjaxRequest(
        END_POINT.create_company.method,
        END_POINT.create_company.url,
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            let errorMessage = '';
            const keys = Object.keys(data.response.errors);
            // eslint-disable-next-line guard-for-in
            for (let key in keys) {
              errorMessage += data.response.errors[keys[key]] + '\n';
            }
            return of(companyActions.createCompanyFailed({errorMessage}));
          }
          return of(companyActions.createCompanySuccess(data.response.data));
        })
      )
    )
  );

const editCompanyEpic = (action$) =>
  action$.pipe(
    ofType(actions.EDIT_COMPANY),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.edit_company.method,
        END_POINT.edit_company.url(action.payload.id),
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(companyActions.editCompanyFailed(data.response.errors));
          }
          return of(companyActions.editCompanySuccess(data.response.data));
        }),
        catchError(error => {console.log(error);})
      )
    )
  );

const deleteCompanyEpic = action$ =>
  action$.pipe(
    ofType(actions.DELETE_COMPANY),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.delete_company.method,
        END_POINT.delete_company.url(action.payload.id)
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(companyActions.deleteCompanyFailed(data.response.errors));
          }
          return of(companyActions.deleteCompanySuccess(data.response));
        }),
        catchError(error => {console.log(error);})
      )
    )
  );
export default [createCompanyEpic, editCompanyEpic, deleteCompanyEpic, fetchCompaniesEpic, fetchCitiesEpic];
