import React from 'react';

// const Orders = React.lazy(() => import('./containers/Orders'));
const SearchOrder = React.lazy(() => import('./view/SearchOrder'));
const routes = [].concat([
  {
    path: '/search-orders',
    component: SearchOrder,
    title: 'Tìm kiếm đơn hàng',
    exact: true,
    private: true,
    roles: [
      'Admin',
      'Accountant',
      'Designer',
      'Manager',
      'Printer',
      'Saler',
      'Store'
    ]
  }
]);

export default routes;
