import SignIn from './containers/SignIn';
import Logout from './containers/Logout';
import Profile from './containers/Profile';

export default [
  {
    path: '/signin',
    component: SignIn
  },
  {
    path: '/logout',
    component: Logout,
    private: true
  }
];
