import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, switchMap } from 'rxjs/operators';
import { makeAjaxRequest } from 'utils/ajax';
import { actions, END_POINT } from './constants';
import orderActions from './actions';

const fetchOrdersEpic = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_ORDERS),
    switchMap((action) => {
      let query = Object.keys(action.payload.params)
        .map((key) => {
          return key + '=' + action.payload.params[key];
        })
        .join('&');
      return makeAjaxRequest(
        END_POINT.fetch_orders.method,
        END_POINT.fetch_orders.url(query)
      ).pipe(
        mergeMap((data) => {
          if (data.response.errors) {
            return of(orderActions.fetchOrdersFailed(data.response.errors));
          }
          return of(
            orderActions.fetchOrdersSuccess({
              orders: data.response.data.items,
              total: data.response.data.total
            })
          );
        }),
        catchError((error) => {
          console.log(error);
        })
      );
    })
  );
const fetchOrders3Epic = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_ORDERS3),
    switchMap((action) => {
      let query = Object.keys(action.payload.params)
        .map((key) => {
          return key + '=' + action.payload.params[key];
        })
        .join('&');
      return makeAjaxRequest(
        END_POINT.fetch_orders3.method,
        END_POINT.fetch_orders3.url(query)
      ).pipe(
        mergeMap((data) => {
          if (data.response.errors) {
            return of(orderActions.fetchOrders3Failed(data.response.errors));
          }
          return of(
            orderActions.fetchOrders3Success({
              orders: data.response.data.items,
              total: data.response.data.total
            })
          );
        })
      );
    })
  );
const fetchOrderDetailEpic = (action$) =>
  action$.pipe(
    ofType(actions.ORDER_DETAIL),
    switchMap((action) => {
      return makeAjaxRequest(
        END_POINT.order_detail.method,
        END_POINT.order_detail.url(action.payload.order_id)
      ).pipe(
        mergeMap((data) => {
          if (data.response.errors) {
            return of(orderActions.orderDetailFailed(data.response.errors));
          }
          return of(
            orderActions.orderDetailSuccess({
              order: data.response.data,
              isBusy: false
            })
          );
        }),
        catchError((error) => {
          console.log(error);
        })
      );
    })
  );
const editOrderEpic = (action$) =>
  action$.pipe(
    ofType(actions.EDIT_ORDER),
    switchMap((action) =>
      makeAjaxRequest(
        END_POINT.edit_order.method,
        END_POINT.edit_order.url(action.payload.order_id),
        action.payload
      ).pipe(
        mergeMap((data) => {
          if (data.response.errors) {
            return of(orderActions.editOrderFailed(data.response.errors));
          }
          return of(
            orderActions.editOrderSuccess({ message: 'Cập nhật thành công.' })
          );
        }),
        catchError((error) => {
          console.log(error);
        })
      )
    )
  );
const fetchOrderIdsEpic = (action$) =>
  action$.pipe(
    ofType(actions.GET_ORDER_IDS),
    switchMap((action) => {
      return makeAjaxRequest(
        END_POINT.get_order_ids.method,
        END_POINT.get_order_ids.url,
        action.payload
      ).pipe(
        mergeMap((data) => {
          if (data.response.errors) {
            return of(orderActions.getOrderIdsFailed(data.response.errors));
          }
          const { callback } = action.payload || {};
          callback && callback();
          return of(
            orderActions.getOrderIdsSuccess({
              selectedOrders: data.response.data,
              isBusy: false
            })
          );
        }),
        catchError((error) => {
          console.log(error);
        })
      );
    })
  );
const sendReceiptEpic = (action$) =>
  action$.pipe(
    ofType(actions.SEND_MAIL_DEBIT),
    switchMap((action) => {
      return makeAjaxRequest(
        END_POINT.send_mails.method,
        END_POINT.send_mails.url,
        { order_ids: action.payload.order_ids }
      ).pipe(
        mergeMap((data) => {
          if (data.response.errors) {
            return of(
              orderActions.sendMailDebitFailed({ error: data.response.errors })
            );
          }
          return of(
            orderActions.sendMailDebitSuccess({ sentMailSuccess: true })
          );
        }),
        catchError((error) => {
          console.log(error);
        })
      );
    })
  );
const fetchTotalDebitProcessing = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_TOTAL_DEBIT_PROCESSING),
    switchMap(() => {
      return makeAjaxRequest(
        END_POINT.total_debit_processing.method,
        END_POINT.total_debit_processing.url
      ).pipe(
        mergeMap((data) => {
          if (data.response.errors) {
            return of(orderActions.fetchTotalDebitProcessingFailed);
          }

          const totalDebitProcessing =
            data?.response?.data?.total_debit -
            data?.response?.data?.total_paid;
          return of(
            orderActions.fetchTotalDebitProcessingSuccess({
              totalDebitProcessing: totalDebitProcessing
            })
          );
        }),
        catchError((error) => {
          console.log(error);
        })
      );
    })
  );
const fetchTotalDebitNeedCheck = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_TOTAL_DEBIT_NEED_CHECK),
    switchMap(() => {
      return makeAjaxRequest(
        END_POINT.total_debit_need_check.method,
        END_POINT.total_debit_need_check.url
      ).pipe(
        mergeMap((data) => {
          console.log('fetchTotalDebitNeedCheck', data);
          if (data.response.errors) {
            return of(orderActions.fetchTotalDebitNeedCheckFailed);
          }
          const totalDebitNeedCheck =
            data?.response?.data?.total_debit -
            data?.response?.data?.total_paid;
          return of(
            orderActions.fetchTotalDebitNeedCheckSuccess({
              totalDebitNeedCheck: totalDebitNeedCheck
            })
          );
        })
      );
    })
  );
export default [
  fetchOrdersEpic,
  fetchOrderDetailEpic,
  editOrderEpic,
  fetchOrderIdsEpic,
  fetchOrders3Epic,
  sendReceiptEpic,
  fetchTotalDebitProcessing,
  fetchTotalDebitNeedCheck
];
