import { handleActions } from 'redux-actions';

const initialState = {
  customerListDebit: [],
  customerDebitName: [],
  isLoading: false,
  total: 0,
  totalDebit: 0,
  totalPaid: 0
};

const customersListDebitReducer = handleActions({
  FETCH_CUSTOMERS_LIST_DEBIT: (state) => ({
    ...state,
    isLoading: true
  }),
  FETCH_CUSTOMER_LIST_NAME_REQUEST: (state) => ({
    ...state,
    customerDebitName: []
  }),
  FETCH_CUSTOMER_LIST_NAME_SUCCESS: (state, actions) => {

    return {
      ...state,
      customerDebitName: actions.payload.items
    };
  },
  FETCH_CUSTOMERS_LIST_DEBIT_SUCCESS: (state, actions) => ({
    ...state,
    customerListDebit: actions.payload.items,
    total: actions.payload.total,
    isLoading: false
  }),
  FETCH_CUSTOMERS_LIST_DEBIT_FAILED: (state, actions) => ({
    ...state,
    errorMessage: actions.payload.message,
    isLoading: false
  }),
  FETCH_TOTAL_DEBIT_REQUEST: (state) => ({
    ...state,
    totalDebit: 0,
    totalPaid: 0
  }),
  FETCH_TOTAL_DEBIT_SUCCESS: (state, actions) => ({
    ...state,
    isLoadingTotal: false,
    totalDebit: actions.payload.total_debit,
    totalPaid: actions.payload.total_paid
  }),
  FETCH_TOTAL_DEBIT_FAILED: (state) => ({
    ...state
  })
}, initialState);

export default customersListDebitReducer;
