import React from 'react';
const Companies = React.lazy(() => import('./containers/Companies'));
const routes = [].concat([
  {
    path: '/companies',
    component: Companies,
    title: 'Công ty',
    exact: true,
    private: true,
    roles: ['Admin']
  },
  {
    path: '/companies',
    component: Companies,
    title: 'Công ty',
    exact: true,
    private: true,
    roles: ['Accountant']
  }
]);

export default routes;
