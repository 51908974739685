export const actions = {
  FETCH_ORDER_DESIGNED: 'Printer:FETCH_ORDER_DESIGNED',
  FETCH_ORDER_DESIGNED_SUCCESS: 'Printer:FETCH_ORDER_DESIGNED_SUCCESS',
  FETCH_ORDER_DESIGNED_FAILED: 'Printer:FETCH_ORDER_DESIGNED_FAILED',
  FETCH_ORDER_PRINTING: 'Printer:FETCH_ORDER_PRINTING',
  FETCH_ORDER_PRINTING_SUCCESS: 'Printer:FETCH_ORDER_PRINTING_SUCCESS',
  FETCH_ORDER_PRINTING_FAILED: 'Printer:FETCH_ORDER_PRINTING_FAILED',
  FETCH_ORDER_DETAIL: 'Printer:FETCH_ORDER_DETAIL',
  FETCH_ORDER_DETAIL_SUCCESS: 'Printer:FETCH_ORDER_DETAIL_SUCCESS',
  FETCH_ORDER_DETAIL_FAILED: 'Printer:FETCH_ORDER_DETAIL_FAILED',
  ASSIGN_ORDER: 'Printer:ASSIGN_ORDER',
  REJECT_ORDER: 'Printer:REJECT_ORDER',
  UPDATE_ORDER: 'Printer:UPDATE_ORDER'
};

export const PrinterOrder = {
  TAB: {
    CARD_ORDER: 'tab_card_order',
    STICKER: 'tab_sticker',
    PAPER: 'tab_paper',
    OTHERS: 'tab_others'
  },
  FILTER_CATEGORY: {
    CARD: 'Card',
    STICKER: 'Sticker',
    PAPER_HAND: 'Tờ rơi',
    OTHERS: 'Khác'
  }
};
export const STATUS_ACTION = {
  reject: 'reject',
  done: 'done'
};
export const ORDER = {
  ORDER_STATUS: {
    CANCEL: -1,
    DRAFT: 0,
    SALE: 1,
    DESIGNING: 2,
    FEEDBACK_DESIGN: 3,
    FEEDBACK_DESIGNING: 4,
    DESIGNED: 5,
    PRINTING: 6,
    PRINTED: 7,
    STORED: 8,
    DELIVER: 9,
    DONE: 10
  },
  ORDER_TYPE: {
    CUSTOM: 'CUSTOM',
    NORMAL: 'NORMAL'
  },
  ORDER_BY: {
    CREATED_TIME: 'created_time',
    UPDATED_TIME: 'updated_time',
    NAME: 'name'
  },
  SORT_BY: {
    ASC: 'asc',
    DESC: 'desc'
  },
  PER_PAGE: {
    DEFAULT: 20
  }
};
export const END_POINT = {
  get_order_list: {
    method: 'GET',
    url: (body) => `orders/${body}`
  },
  update_order: {
    method: 'PUT',
    url: (id) => `orders/${id}`
  },
  assign_order: {
    method: 'PUT',
    url: (id) => `orders/${id}/assign`
  },
  reject_order: {
    method: 'PUT',
    url: (id) => `orders/${id}/reject`
  }
};
