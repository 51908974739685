
import createReducers from 'create-reducers';
import { reportActions } from './constants';
const initialState = {
  categories: {
    isLoad: false,
    data: []
  },
  printTypes: {
    isLoad: false,
    data: []
  },
  incomeRest: {
    isLoad: false,
    data: []
  },
  incomeCurrent: {
    isLoad: false,
    data: []
  },
  employeeLastMonth: {
    isLoad: false,
    data: []
  },
  employeeInYear: {
    isLoad: false,
    data: []
  },
  employeeCurrent: {
    isLoad: false,
    data: []
  },
  employeeChartInYear: {
    isLoad: false,
    data: []
  },
  allUser: {
    isLoad: false,
    data: []
  }
};

const reportVenueReducer = createReducers(initialState, reportActions);

export default reportVenueReducer;
