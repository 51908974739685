export const actions = {
  SET_STATE: 'SET_STATE',
  FETCH_USERS: 'FETCH_USERS',
  FETCH_USERS_FAILED: 'FETCH_USERS_FAILED',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  CREATE_USER: 'CREATE_USER',
  CREATE_USER_FAILED: 'CREATE_USER_FAILED',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  EDIT_USER: 'EDIT_USER',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILED: 'EDIT_USER_FAILED',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILED: 'DELETE_USER_FAILED'
};

export const END_POINT = {
  fetch_users: {
    url: 'users',
    method: 'GET'
  },
  create_user: {
    url: 'users',
    method: 'POST'
  },
  edit_user: {
    url: (uid) => `users/${uid}`,
    method: 'PUT'
  },
  delete_user: {
    url: (uid) => `users/${uid}`,
    method: 'DELETE'
  }
};
