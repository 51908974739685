import createReducers from 'create-reducers';
import { actions } from './constants';
const initialState = {
  isBusy: false,
  printTypes: {},
  paperTypes: [],
  outsources: [],
  categories: [],
  categoriesMobile: [],
  categoriesParent: []
};

const materialReducer = createReducers(initialState, actions);

export default materialReducer;
