export const actions = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_REJECT: 'LOGIN_REJECT',
  FETCH_USER_INFO: 'FETCH_USER_INFO',
  FETCH_USER_INFO_SUCCESS: 'FETCH_USER_INFO_SUCCESS',
  FETCH_USER_INFO_FAILED: 'FETCH_USER_INFO_FAILED',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_PROFILE_FAILED: 'UPDATE_PROFILE_FAILED',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS'
};

export const END_POINT = {
  sign_in: {
    url: 'users/session',
    method: 'POST'
  },
  fetch_user: {
    url: 'users/me',
    method: 'GET'
  },
  logout: {
    url: 'users/session',
    method: 'DELETE'
  },
  profile: {
    url: 'users/profile',
    method: 'POST'
  }
};
