import { ofType } from 'redux-observable';
import { of} from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { makeAjaxRequest } from 'utils/ajax';
import { actions, END_POINT } from './constants';
import customerActions from './actions';

const fetchCustomersEpic = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_CUSTOMERS),
    switchMap(action => {
      let query = Object.keys(action.payload.params).map((key) => {
        return key + '=' + action.payload.params[key];
      }).join('&');
      return makeAjaxRequest(
        END_POINT.fetch_customers.method,
        END_POINT.fetch_customers.url(query)
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(customerActions.fetchCustomersFailed(data.response.errors));
          }
          return of(customerActions.fetchCustomersSuccess(data.response.data));
        }),
        catchError(error => {console.log(error);})
      );
    }));

const createCustomerEpic = (action$) =>
  action$.pipe(
    ofType(actions.CREATE_CUSTOMER),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.create_customer.method,
        END_POINT.create_customer.url,
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(customerActions.createCustomerFailed(data.response.errors));
          }
          return of(customerActions.createCustomerSuccess(data.response.data));
        }),
        catchError(error => {console.log(error);})
      )
    )
  );

const editCustomerEpic = (action$) =>
  action$.pipe(
    ofType(actions.EDIT_CUSTOMER),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.edit_customer.method,
        END_POINT.edit_customer.url(action.payload.id),
        action.payload
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(customerActions.editCustomerFailed(data.response.errors));
          }
          return of(customerActions.editCustomerSuccess(data.response.data));
        }),
        catchError(error => {console.log(error);})
      )
    )
  );

const deleteCustomerEpic = action$ =>
  action$.pipe(
    ofType(actions.DELETE_CUSTOMER),
    switchMap(action =>
      makeAjaxRequest(
        END_POINT.delete_customer.method,
        END_POINT.delete_customer.url(action.payload.id)
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(customerActions.deleteCustomerFailed(data.response.errors));
          }
          return of(customerActions.deleteCustomerSuccess(data.response));
        }),
        catchError(error => {console.log(error);})
      )
    )
  );
export default [fetchCustomersEpic, createCustomerEpic, editCustomerEpic, deleteCustomerEpic];
