import { handleActions } from 'redux-actions';

const initialState = {
  users: [],
  isBusy: false
};

const usersReducer = handleActions({
  SET_STATE: (state, action) => ({
    ...state,
    ...action.payload
  }),
  FETCH_USERS: (state) => ({
    ...state,
    isBusy: true
  }),
  FETCH_USERS_SUCCESS: (state, actions) => ({
    ...state,
    users: actions.payload,
    isBusy: false
  }),
  CREATE_USER_SUCCESS: (state, actions) => ({
    ...state,
    message: 'Tạo user thành công.',
    isBusy: false
  }),
  CREATE_USER_FAILED: (state, actions) => ({
    ...state,
    errorMessage: actions.payload.message,
    isBusy: false
  }),
  EDIT_USER_SUCCESS: (state, actions) => ({
    ...state,
    message: 'Sửa user thành công.',
    isBusy: false
  })
}, initialState);

export default usersReducer;
