import React from 'react';

// const Orders = React.lazy(() => import('./containers/Orders'));
const OrdersProcessing = React.lazy(() => import('./view/OrdersProcessing'));
const OrderByCustomer = React.lazy(() => import('./view/OrderByCustomer'));
const OrderNeedCheck = React.lazy(() => import('./view/OrderNeedCheck'));
const OrderStored = React.lazy(() => import('./view/OrderStored'));
const OrderConfirm = React.lazy(() => import('./view/OrderConfirm'));
const routes = [].concat([
  // {
  //   path: '/orders',
  //   component: Orders,
  //   title: 'Đơn hàng',
  //   exact: true,
  //   private: true,
  //   roles: ['Admin']
  // },
  {
    path: '/orders-processing',
    component: OrdersProcessing,
    title: 'Đơn hàng đang thực hiện',
    exact: true,
    private: true,
    roles: ['Admin', 'Accountant', 'Saler', 'Store', 'Manager']
  },
  // {
  //   path: '/orders',
  //   component: Orders,
  //   title: 'Đơn hàng đang thực hiện',
  //   exact: true,
  //   private: true,
  //   roles: ['Admin', 'Accountant', 'Saler']
  // },
  {
    path: '/customer-orders',
    component: OrderByCustomer,
    title: 'Đơn hàng cần thanh toán',
    exact: true,
    private: true,
    roles: ['Admin', 'Accountant', 'Saler', 'Store']
  },
  {
    path: '/need-check-orders',
    component: OrderNeedCheck,
    title: 'Đơn hàng cần kiểm tra',
    exact: true,
    private: true,
    roles: ['Admin', 'Accountant', 'Manager']
  },
  {
    path: '/stored-orders',
    component: OrderStored,
    title: 'Đơn hàng lưu kho',
    exact: true,
    private: true,
    roles: ['Admin', 'Accountant', 'Saler', 'Store', 'Manager']
  },
  {
    path: '/confirm-orders',
    component: OrderConfirm,
    title: 'Đơn hàng cần xác nhận',
    exact: true,
    private: true,
    roles: ['Admin', 'Accountant']
  }
]);

export default routes;
