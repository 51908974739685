import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from './epics';
const epicMiddleware = createEpicMiddleware(rootEpic);

// const middleware = [epicMiddleware];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(epicMiddleware))
);
export default store;
