import React, {Component} from 'react';
import PropTypes from 'prop-types';
import windowSize from 'react-window-size';

import NavSearch from './NavSearch';

class NavLeft extends Component {

  render() {
    let iconFullScreen = ['feather'];
    iconFullScreen = (this.props.isFullScreen) ? [...iconFullScreen, 'icon-minimize'] : [...iconFullScreen, 'icon-maximize'];

    let navItemClass = ['nav-item'];
    if (this.props.windowWidth <= 575) {
      navItemClass = [...navItemClass, 'd-none'];
    }
    let dropdownRightAlign = false;
    if (this.props.rtlLayout) {
      dropdownRightAlign = true;
    }

    return (
      <>
        <ul className='navbar-nav mr-auto'>
          {/* <li><a href='#!' className='full-screen' onClick={this.props.onFullScreen}><i className={iconFullScreen.join(' ')} /></a></li> */}
          {/* <li className='nav-item'><NavSearch/></li> */}
        </ul>
      </>
    );
  }
}
NavLeft.propTypes = {
  onFullScreen: PropTypes.func,
  rtlLayout: PropTypes.bool,
  windowWidth: PropTypes.number,
  isFullScreen: PropTypes.bool
};
export default windowSize(NavLeft);
