import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { makeAjaxRequest } from 'utils/ajax';
import { actions, END_POINT } from './constants';
import customerListDebitActions from './actions';

const fetchCustomersListDebitEpic = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_CUSTOMERS_LIST_DEBIT),
    switchMap(action => {
      let query = Object.keys(action.payload.params).map((key) => {
        return key + '=' + action.payload.params[key];
      }).join('&');
      return makeAjaxRequest(
        END_POINT.fetch_customers_list_debit.method,
        END_POINT.fetch_customers_list_debit.url(query)
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(customerListDebitActions.fetchCustomersListDebitFailed(data.response.errors));
          }
          return of(customerListDebitActions.fetchCustomersListDebitSuccess(data.response.data));
        }),
        catchError(error => { console.log(error); })
      );
    }));
const fetchCustomersListNameEpic = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_CUSTOMER_LIST_NAME_REQUEST),
    switchMap(action => {
      let query = Object.keys(action.payload.params).map((key) => {
        return key + '=' + action.payload.params[key];
      }).join('&');
      return makeAjaxRequest(
        END_POINT.fetch_customers_list_debit.method,
        END_POINT.fetch_customers_list_debit.url(query)
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(customerListDebitActions.fetchCustomerListNameFailed(data.response.errors));
          }
          return of(customerListDebitActions.fetchCustomerListNameSuccess(data.response.data));
        }),
        catchError(error => { console.log(error); })
      );
    }));

const fetchTotalDebitAndPaid = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_TOTAL_DEBIT_REQUEST),
    switchMap(() => {
      return makeAjaxRequest(
        END_POINT.fetch_total_debit_and_paid.method,
        END_POINT.fetch_total_debit_and_paid.url
      ).pipe(
        mergeMap(data => {
          if (data.response.errors) {
            return of(customerListDebitActions.fetchTotalDebitFailed);
          }
          return of(customerListDebitActions.fetchTotalDebitSuccess(data.response.data));
        }),
        catchError(error => { console.log(error); })
      );
    }));
export default [fetchCustomersListDebitEpic, fetchCustomersListNameEpic, fetchTotalDebitAndPaid];
