import { useEffect, useRef } from 'react';

const checkRoles = (roles, user) => {
  if (!roles) {
    return true;
  }
  const userRoles = user.roles.filter((item) => {
    return roles.includes(item.name);
  });
  if (userRoles && userRoles.length) {
    return true;
  }
  return false;
};
const numberWithCommas = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '-';
};
const doNoThing = () => null;

function useDidUpdate(callback, deps) {
  const hasMount = useRef(false);

  useEffect(() => {
    if (hasMount.current) {
      callback();
    } else {
      hasMount.current = true;
    }
  }, deps);
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export { checkRoles, numberWithCommas, doNoThing, useDidUpdate, usePrevious };
