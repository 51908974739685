import { handleActions } from 'redux-actions';
import { actions } from './constants';

const initialState = {
  isUserActive: true,
  currentUser: {},
  isLogging: false,
  gettingUser: false,
  error: null,
  token: null,
  isBusy: false
};

const authReducer = handleActions({
  REQUEST_LOGIN: (state) => ({
    ...state,
    isBusy: true
  }),
  LOGIN_SUCCESS: (state, action) => ({
    ...state,
    currentUser: action.payload,
    error: null,
    isBusy: false
  }),
  LOGIN_REJECT: (state, action) => ({
    ...state,
    error: action.payload.messages,
    isBusy: false
  }),
  FETCH_USER_INFO_SUCCESS: (state, action) => ({
    ...state,
    currentUser: action.payload
  }),
  FETCH_USER_INFO_FAILED: (state, action) => ({
    ...state,
    messages: action.payload.messages
  }),
  LOGOUT_SUCCESS: (state) => ({
    ...state,
    currentUser: {}
  }),
  UPDATE_PROFILE_SUCCESS: (state) => ({
    ...state,
    message: 'Cập nhật thành công'
  })
}, initialState);

export default authReducer;
