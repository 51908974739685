import React from 'react';
const Users = React.lazy(() => import('./containers/Users'));
const Profile = React.lazy(() => import('./containers/Profile'));
const routes = [].concat([
  {
    path: '/profile',
    component: Profile,
    exact: true,
    private: true
  },
  {
    path: '/users',
    component: Users,
    title: 'Users',
    exact: true,
    private: true,
    roles: ['Admin']
  }
]);

export default routes;
