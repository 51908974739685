import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, switchMap } from 'rxjs/operators';
import { makeAjaxRequest } from 'utils/ajax';
import { actions, END_POINT } from './constants';
import searchOrderActions from './actions';

const fetchSearchOrder = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_SEARCH_ORDER),
    switchMap((action) => {
      let query = Object.keys(action.payload.params)
        .map((key) => {
          return key + '=' + action.payload.params[key];
        })
        .join('&');
      return makeAjaxRequest(
        END_POINT.fetch_search.method,
        END_POINT.fetch_search.url(query)
      ).pipe(
        mergeMap((data) => {
          if (data.response.errors) {
            return of(
              searchOrderActions.fetchSearchOrderFailed({
                isLoadingSearch: false
              })
            );
          }
          return of(
            searchOrderActions.fetchSearchOrderSuccess({
              orderListSearch: data.response.data.items,
              totalOrder: data.response.data.total,
              isLoadingSearch: false
            })
          );
        }),
        catchError((error) => {
          console.log(error);
        })
      );
    })
  );
export default [fetchSearchOrder];
