export const actions = {
  SET_STATE: 'SET_STATE',
  FETCH_STATISTIC: 'FETCH_STATISTIC',
  FETCH_STATISTIC_SUCCESS: 'FETCH_STATISTIC_SUCCESS',
  FETCH_STATISTIC_FAILED: 'FETCH_STATISTIC_FAILED',
  FETCH_ORDER_DETAIL: 'FETCH_ORDER_DETAIL',
  FETCH_ORDER_DETAIL_FAILED: 'FETCH_ORDER_DETAIL_FAILED',
  FETCH_ORDER_DETAIL_SUCCESS: 'FETCH_ORDER_DETAIL_SUCCESS',
  FETCH_STATISTIC_INCOME: 'FETCH_STATISTIC_INCOME',
  FETCH_STATISTIC_INCOME_SUCCESS: 'FETCH_STATISTIC_INCOME_SUCCESS',
  FETCH_STATISTIC_INCOME_FAILED: 'FETCH_STATISTIC_INCOME_FAILED'

};

export const reportActions = {
  FETCH_REPORT_CATEGORIES: 'FETCH_REPORT_CATEGORIES',
  FETCH_REPORT_CATEGORIES_SUCCESS: 'FETCH_REPORT_CATEGORIES_SUCCESS',
  FETCH_REPORT_CATEGORIES_FAILED: 'FETCH_REPORT_CATEGORIES_FAILED',
  FETCH_REPORT_PRINT_TYPES: 'FETCH_REPORT_PRINT_TYPES',
  FETCH_REPORT_PRINT_TYPES_SUCCESS: 'FETCH_REPORT_PRINT_TYPES_SUCCESS',
  FETCH_REPORT_PRINT_TYPES_FAILED: 'FETCH_REPORT_PRINT_TYPES_FAILED',
  FETCH_REPORT_INCOME: 'FETCH_REPORT_INCOME',
  FETCH_REPORT_INCOME_SUCCESS: 'FETCH_REPORT_INCOME_SUCCESS',
  FETCH_REPORT_INCOME_FAILED: 'FETCH_REPORT_INCOME_FAILED',
  FETCH_REPORT_EMPLOYEE: 'FETCH_REPORT_EMPLOYEE',
  FETCH_REPORT_EMPLOYEE_SUCCESS: 'FETCH_REPORT_EMPLOYEE_SUCCESS',
  FETCH_REPORT_EMPLOYEE_FAILED: 'FETCH_REPORT_EMPLOYEE_FAILED',
  FETCH_REPORT_EMPLOYEE_CHART: 'FETCH_REPORT_EMPLOYEE_CHART',
  FETCH_REPORT_EMPLOYEE_CHART_SUCCESS: 'FETCH_REPORT_EMPLOYEE_CHART_SUCCESS',
  FETCH_REPORT_EMPLOYEE_CHART_FAILED: 'FETCH_REPORT_EMPLOYEE_CHART_FAILED',
  FETCH_REPORT_ALL_EMPLOYEE_INCOME: 'FETCH_REPORT_ALL_EMPLOYEE_INCOME',
  FETCH_REPORT_ALL_EMPLOYEE_INCOME_SUCCESS: 'FETCH_REPORT_ALL_EMPLOYEE_INCOME_SUCCESS',
  FETCH_REPORT_ALL_EMPLOYEE_INCOME_FAILED: 'FETCH_REPORT_ALL_EMPLOYEE_INCOME_FAILED'
};
export const END_POINT = {
  fetch_statistic: {
    method: 'GET',
    url: (params) => `orders/statistic?${params}`
  },
  fetch_income: {
    method: 'GET',
    url: (params) => `orders/income?${params}`
  },
  fetch_order: {
    method: 'GET',
    url: (order_id) => `orders/${order_id}`
  },
  fetch_report_categories: {
    method: 'GET',
    url: (params) => `reports/categories?${params}`
  },
  fetch_report_print_types: {
    method: 'GET',
    url: (params) => `reports/print-types?${params}`
  },
  fetch_report_income: {
    method: 'GET',
    url: (params) => `reports/income?${params}`
  },
  fetch_report_employee: {
    method: 'GET',
    url: (params) => `reports/employee?${params}`
  },
  fetch_report_employee_chart: {
    method: 'GET',
    url: (params) => `reports/employee/chart?${params}`
  }
};
