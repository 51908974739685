import { handleActions } from 'redux-actions';
import { actions, PrinterOrder } from './constants';
const initialState = {
  listWaiting: {
    [PrinterOrder.TAB.CARD_ORDER]: {
      list: [],
      isLoading: false,
      total: 0
    },
    [PrinterOrder.TAB.STICKER]: {
      list: [],
      isLoading: false,
      total: 0
    },
    [PrinterOrder.TAB.PAPER]: {
      list: [],
      isLoading: false,
      total: 0
    },
    [PrinterOrder.TAB.OTHERS]: {
      list: [],
      isLoading: false,
      total: 0
    }
  },
  listPrinting: {
    list: [],
    total: 0,
    isLoading: false
  }
};
const orderPrintReducer = handleActions({
  [actions.FETCH_ORDER_DESIGNED_SUCCESS]: (state, action) => {
    const { response, tab } = action.payload || {};
    const { items = [], total } = response;
    const { listWaiting } = state;
    return {
      ...state,
      listWaiting: {
        ...listWaiting,
        [tab]: {
          list: items,
          total,
          isLoading: false
        }
      }
    };
  },
  [actions.FETCH_ORDER_PRINTING_SUCCESS]: (state, action) => {
    const { response } = action.payload || {};
    const { items = [], total } = response;
    return {
      ...state,
      listPrinting: {
        list: items,
        total,
        isLoading: false
      }
    };
  }
}, initialState);

export default orderPrintReducer;
