import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { bindActionCreators } from 'redux';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import authActions from '../actions';

class SignIn extends React.Component {
  static propTypes = {
    authActions: PropTypes.object,
    currentUser: PropTypes.object,
    history: PropTypes.object,
    error: PropTypes.string
  }
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      currentUser: {}
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.currentUser && !isEqual(props.currentUser, state.currentUser)) {
      return {
        currentUser: props.currentUser
      };
    }
    return null;
  }

  componentDidUpdate() {
    const { currentUser, history } = this.props;
    if (currentUser && currentUser.id) {
      history.push('/');
    }
  }

  login = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props.authActions.login({ identity: email, password });
  }

  onChange = e => {
    const { name, value } = e.target;
    if (name) {
      this.setState({ [name]: value });
    }
  };

  render() {
    const { email, password } = this.state;
    const { error } = this.props;
    return (
      <>
        <div className='auth-wrapper'>
          <div className='auth-content'>
            <div className='auth-bg'>
              <span className='r' />
              <span className='r s' />
              <span className='r s' />
              <span className='r' />
            </div>
            <div className='card'>
              <div className='card-body text-center'>
                <div className='mb-4'>
                  <i className='feather icon-unlock auth-icon' />
                </div>
                <h3 className='mb-4'>Login</h3>
                {(error) &&
                  <Alert variant='danger'>
                    {error}
                  </Alert>
                }
                <form onSubmit={this.login}>
                  <div className='input-group mb-3'>
                    <input type='email' className='form-control' placeholder='Email' name='email' onChange={this.onChange} value={email} />
                  </div>
                  <div className='input-group mb-4'>
                    <input type='password' className='form-control' placeholder='password' onChange={this.onChange} name='password' value={password} />
                  </div>
                  <button className='btn btn-primary shadow-2 mb-4'>Login</button>
                  <p className='mb-2 text-muted'>Forgot password? <NavLink to='/auth/reset-password-1'>Reset</NavLink></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.AuthReducer.currentUser,
  error: state.AuthReducer.error
});

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(authActions, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
