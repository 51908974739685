import { handleActions } from 'redux-actions';

const initialState = {
  customers: [],
  isBusy: false
};

const customersReducer = handleActions({
  SET_STATE: (state, action) => ({
    ...state,
    ...action.payload
  }),
  FETCH_CUSTOMERS: (state) => ({
    ...state,
    isBusy: true
  }),
  FETCH_CUSTOMERS_SUCCESS: (state, actions) => ({
    ...state,
    customers: actions.payload.items,
    total: actions.payload.total,
    isBusy: false
  }),
  CREATE_CUSTOMER_SUCCESS: (state, actions) => ({
    ...state,
    message: 'Tạo khách hàng thành công.',
    isBusy: false
  }),
  CREATE_CUSTOMER_FAILED: (state, actions) => ({
    ...state,
    errorMessage: actions.payload.message,
    isBusy: false
  }),
  EDIT_CUSTOMER_SUCCESS: (state, actions) => ({
    ...state,
    message: 'Sửa khách hàng thành công.',
    isBusy: false
  })
}, initialState);

export default customersReducer;
