
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError, switchMap } from 'rxjs/operators';
import { makeAjaxRequest } from 'utils/ajax';
import { actions, END_POINT, ORDER } from './constants';
import orderActions from './actions';
import * as helper from './helper';
const fetchOrdersDesignedEpic = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_ORDER_DESIGNED),
    switchMap((action) => {
      const paramsUri = helper.mergeParamsToUrl(
        ORDER.ORDER_STATUS.DESIGNED,
        ORDER.ORDER_BY.UPDATED_TIME,
        ORDER.SORT_BY.DESC,
        ORDER.PER_PAGE.DEFAULT,
        action.payload.page,
        action.payload.moreParams,
      );
      return makeAjaxRequest(
        END_POINT.get_order_list.method,
        END_POINT.get_order_list.url(paramsUri)
      ).pipe(
        mergeMap((data) => {
          if (data.response.errors) {
            return of(orderActions.fetchOrderDesignedFailed(data.response.errors));
          }
          return of(
            orderActions.fetchOrderDesignedSuccess({
              response: data.response.data,
              tab: action.payload.tab
            })
          );
        }),
        catchError((error) => {
          console.log(error);
        })
      );
    })
  );
const fetchOrdersPrintingEpic = (action$) =>
  action$.pipe(
    ofType(actions.FETCH_ORDER_PRINTING),
    switchMap((action) => {
      const paramsUri = helper.mergeParamsToUrl(
        ORDER.ORDER_STATUS.PRINTING,
        ORDER.ORDER_BY.UPDATED_TIME,
        ORDER.SORT_BY.DESC,
        ORDER.PER_PAGE.DEFAULT,
        action.payload.page,
      );
      return makeAjaxRequest(
        END_POINT.get_order_list.method,
        END_POINT.get_order_list.url(paramsUri)
      ).pipe(
        mergeMap((data) => {
          if (data.response.errors) {
            return of(orderActions.fetchOrderPrintingFailed(data.response.errors));
          }
          return of(
            orderActions.fetchOrderPrintingSuccess({
              response: data.response.data
            })
          );
        }),
        catchError((error) => {
          console.log(error);
        })
      );
    })
  );

const assignOrderEpic = (action$) =>
  action$.pipe(
    ofType(actions.ASSIGN_ORDER),
    switchMap((action) => {
      return makeAjaxRequest(
        END_POINT.assign_order.method, END_POINT.assign_order.url(action.payload.data.id), action.payload.data
      ).pipe(
        mergeMap((data) => {
          const { callback = () => { } } = action.payload;
          if (data.response.errors) {
            return callback(false);
          }
          return callback(true);
        }),
        catchError((error) => {
          console.log(error);
        })
      );
    })
  );
const rejectOrderEpic = (action$) =>
  action$.pipe(
    ofType(actions.REJECT_ORDER),
    switchMap((action) => {
      return makeAjaxRequest(
        END_POINT.reject_order.method, END_POINT.reject_order.url(action.payload.data.id), action.payload.data
      ).pipe(
        mergeMap((data) => {
          const { callback = () => { } } = action.payload;
          if (data.response.errors) {
            return callback(false);
          }
          return callback(true);
        }),
        catchError((error) => {
          console.log(error);
        })
      );
    })
  );
const updateOrderEpic = (action$) =>
  action$.pipe(
    ofType(actions.UPDATE_ORDER),
    switchMap((action) => {
      return makeAjaxRequest(
        END_POINT.update_order.method, END_POINT.update_order.url(action.payload.data.id), action.payload.data
      ).pipe(
        mergeMap((data) => {
          const { callback = () => { } } = action.payload;
          if (data.response.errors) {
            return callback(false);
          }
          return callback(true);
        }),
        catchError((error) => {
          console.log(error);
        })
      );
    })
  );

export default [
  fetchOrdersDesignedEpic,
  fetchOrdersPrintingEpic,
  assignOrderEpic,
  rejectOrderEpic,
  updateOrderEpic
];
